////
/// @group _partials/form.scss
////


// scss
// ================================

.form {
	position: relative;
	font-size: 18px;
	&__toggler-wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
	&__toggler-block{
		background-color: $c-poo;
		padding: 4px 9px;
		display: flex;
	    justify-content: space-between;
	    align-items: center;
	    @include media($lg){
	        padding: 16px 20px;
	    }
	}
	&__toggler-text{
		font-weight: bold;
		color: $c-white;
		font-size: 14px;
		font-family: 'MagistralC';
    	transition: color 0.5s ease;
		@include media($sm){
			font-size: 18px;
		}
		@include media($md){
			font-size: 24px;
		}
		&.is-active{
			color: $c-orange;
		}
	}
	&__toggler{
		height: 26px;
		width: 52px;
		border: 4px solid $c-white;
		background-color: $c-white;
		border-radius: 20px;
		cursor: pointer;
		margin: 0 12px;
		position: relative;
		@include media($md){
			margin: 0 26px;
		}

		@include media($lg){
			width: 82px;
			height: 36px;
		}
	}
	&__toggler-group{
		display: flex;
		align-items: center;
	}
	&__toggler-btn{
		position: absolute;
		border-radius: 100%;
		left: 0;
		top: 0;
		right: auto;
			width: 20px;
			height: 20px;
		transition: left 0.5s ease, margin 0.5s ease;
		background-color: $c-orange;
		@include media($lg){
			width: 28px;
			height: 28px;
		}
		&.is-active{
			left: 100%;
			margin-left: -28px;
		}
	}
	&__group {
		display: block;
		position: relative;
	}
	&__label {
		cursor: default;
		display: inline-block;
		vertical-align: top;
		user-select: none;
		margin-bottom: .4rem;
	}

	&__caption {
		font-size: 16px;
		font-weight: bold;
		font-family: 'MagistralC';
		margin-bottom: 4px;
		color: $c-white;
	}

	&__info {
		display: none;
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 0;
		font-size: .7em;
		background-color: #000;
		color: #fff;
		padding: .1em .3em;
	}
}

label.form__label {
	cursor: pointer;
}

.control-holder {
	position: relative;
	&.weight-elev-container label {
		right: 0;
		min-width: 142px;
		font-size: 9px !important;
		margin: 17px -9px 0 0;
		@include media($lg){
			margin: 28px -10px 0 0;
			min-width: 189px;
			font-size: 12px !important;
		}
	}
	label.has-error {
		display: inline-block;
		vertical-align: top;
		user-select: none;
		font-size: .75em;
		line-height: 1em;
		padding: .1em .5em;
		background-color: #000;
		color: #fff;
		position: absolute;
		top: 100%;
	}

	&--text {
		input, textarea, select {
			display: block;
			width: 100%;
			border: 1px solid $c-orange;
			outline: none;
			padding: 0 10px;
			color: $c-blue;
			font-family: 'MagistralC';
			line-height: 1.3em;
			font-size: 14px;
			@include media($lg){
				padding: 0 rem(20);
				font-size: 18px;
			}
		}
		textarea {
			resize: vertical;
			min-height: 7em;
			max-height: 18em;
		}
		input, select {
			height: 40px;
			@include media($lg){
				height: 56px;
			}
		}
	}

	&--flag {
		label {
			user-select: none;
			margin-right: 1em;
			cursor: pointer;
			position: relative;
			z-index: 1;
			&:only-child,
			&.has-error {
				cursor: default;
				margin-right: 0;
			}
		}
		ins {
			position: relative;
			display: inline-block;
			vertical-align: text-bottom;
			width: 1.2em;
			height: 1.2em;
			background-color: #fff;
			border: 1px solid;
		}
		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
			z-index: -1;
			&[type="radio"] {
				~ ins {
					border-radius: 50%;
				}
			}
			&:checked {
				~ ins {
					background-color: #000;
					box-shadow: inset 0 0 0 3px #fff;
				}
			}
			&:disabled {
				~ ins,
				~ span {
					opacity: .5;
					cursor: not-allowed;
				}
			}
		}
	}

	&--file {
		label {
			position: relative;
			display: inline-block;
			vertical-align: top;
		}

		input {
			position: absolute;
			outline: none;
			top: 0;
			left: 0;
			width: 0;
			height: 0;
			opacity: 0;
		}

		._ellipsis {
			max-width: 200px;
			width: auto;
			display: inline-block;
			vertical-align: top;
		}
	}
}
