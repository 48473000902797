.wus-block{
	position: relative;
	margin-bottom: 70px;
	&__grid{
		display: flex;
		margin-right: 44px;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-left: 44px;
		@include media($mg){
			margin-right: 2vw;
			margin-left: 0;
		}
	}	
	&__view-size{
		max-width: 1550px;
		margin: 0 auto;
		width: 100%;
	}
}
.wus-item{
	display: flex;
	flex-direction: column;
	border-right: 1px solid $c-orange;
	border-left: 1px solid $c-orange;
	padding: 0 12px;
	width: 33.33%;
	margin-bottom: 0;
	@include media($xl){
		width: 20%;
		border-right: 1px solid $c-orange;
		border-left: none;
		margin-bottom: 40px;
	}
	@include media($mg){
		padding: 0 28px;
	}
	&__image{
		height: 132px;
		svg{
			fill: $c-orange;
		}
	}
	&__title{
		display: block;
		color: $c-blue;
		line-height: 100%;
		margin-bottom: 20px;
		b{
			display: block;
		}
	}
	&__text{
		color: $c-lbgrey;
		font-size: 16px;
		line-height: 24px;
	}
	&:nth-child(1){
		.wus-item__image-wrapper{
			transform: translateY(-10px);
		}
		svg{
			width: 96px;
			height: 104px;
		}
	}
	&:nth-child(2){
		.wus-item__image-wrapper{
			transform: translateY(-18px);
		}
		svg{
			width: 132px;
			height: 112px;
		}
	}
	&:nth-child(3){
		svg{
			width: 90px;
			height: 90px;
		}
	}
	&:nth-child(4){
		svg{
			width: 90px;
			height: 90px;
		}
	}
	&:nth-child(5){
		svg{
			width: 94px;
			height: 90px;
		}
	}
	&:first-of-type{
		border-left: 1px solid $c-orange;
	}
}
@media (max-width: 1023px){
	.wus-item{
		width: 300px;
	}
	.wus-block__grid{
		justify-content: center;
	}
}