.main-block{
	width: 100%;
	margin-bottom: 10px;
	@include media($def){
		margin-bottom: 30px;
	}
	&__grid{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 20px;
		@include media($def){
			margin-bottom: 40px;
		}
	}
}
.main-block-item{
	position: relative;
	height: calc(50vh - 32px);
	width: 100%;
	padding: 20px 16px;
	text-decoration: none;
	overflow: hidden;
	max-height: 326px;
	@include media($ms){
		width: 50%;
		height: 326px;
		padding: 50px 40px;
	}
	@include media($def){
		height: calc(50vh - 55px);
	}
	&:hover{
		.main-block-item__image{
			transform: scale(1.2);
			transition: transform 10s ease;
		}
		.main-block-item__text{
			color: $c-orange;
		}
		.bg-holder__left{
			opacity: 1;
			transform: scale(1);
		}
	}
	&__image{
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		transition: transform 6s ease;
	}
	&__text{
		color: $c-white;
		z-index: 25;
		position: relative;
		text-transform: uppercase;
		transition: color 0.5s ease;
		line-height: 100%;
		font-weight: 300;
		letter-spacing: 0.1em;
		font-size: 30px;
		@include media($md){
			font-size: 40px;
		}
		@include media($def){
			font-size: 48px;
		}
		b{
			display: block;
		}
		&:after{
			position: absolute;
			content: "";
			left: 4px;
			bottom: calc(100% + 6px);
			height: 48px;
			width: 1px;
			background-color: $c-orange;
		}
	}
	&__overlay{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		&:before,
		&:after{
			content: "";
			position: absolute;
		}
		&:after{
			opacity: 0.5;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: $c-black;
		}
		&:before{
			top: -220px;
			left: -220px;
			width: 440px;
			height: 440px;
			z-index: 1;
			background: radial-gradient(ellipse at center, rgba(254, 204, 0, 0.5), rgba(254, 204, 0, 0.3), rgba(254, 204, 0, 0.1), transparent, transparent);
		}
		.bg-holder__left{
			opacity: 0;
			transition: opacity 0.5s ease, transform 0.3s 0.2s ease;
			transform: scale(0.8);
			z-index: 26;
		}
		.bg-holder__left--advantages span{
			top: 0;
		}
	}
}