
body,body *{
     &::-webkit-scrollbar-corner {
        background-color: transparent;
        display: none;
    }
    &::-webkit-scrollbar {
        width: 6px;
        cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $c-blue;
        border: 2px solid $c-blue;
        border-radius: 36px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
}
