
.button{
	display: inline-block;
	height: 40px;
	line-height: 60px;
	outline: none;
	position: relative;
	z-index: 1;
	text-decoration: none;
	backface-visibility: hidden;
	border: none;
	padding: 0;
	transition: transform 0.3s ease;
	min-width: 300px;
	background-color: transparent;
	@include media($md){
		min-width: 460px;
	}
	@include media($lg){
		height: 60px;
	}

	&__content{
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		font-weight: normal;
		font-family: 'Bebas Neue';
		position: relative;
		letter-spacing: 1px;
		z-index: 10;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 14px;
		width: 100%;
		height: 100%;
		overflow: hidden;
		min-width: 100%;
		color: inherit;
		letter-spacing: 0.05em;
		transition: color 0.5s 0.2s ease;
		@include media($def){
			font-size: 24px;
		}
		
		span{
			line-height: 100%;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
		    transform: scale(1);
		    opacity: 1;
		    position: relative;
		    z-index: 10;
    		transition: transform .4s .4s cubic-bezier(.455,.03,.515,.955), opacity .4s .4s cubic-bezier(.455,.03,.515,.955);
		}
		
	}
	&--disabled{
		pointer-events: none;
	}
	&--primary{
		// color: $c-white;
		// background-color: $c-blue;
		// .button__content{
		// 	&:before{
				
		// 	}
		// }
		color: $c-dblue;
		border: 1px solid $c-dblue;
		background-color: $c-white;
		&:before{
			background-color: $c-blue;
		}
		&:hover{
			&:after{
				background-color: $c-blue;
			}
		}
	}

	&--second{
		color: $c-dblue;
		border: 1px solid $c-dblue;
		background-color: $c-white;
		&:after,
		&:before{
			background-color: $c-orange;
		}
	}
	&--small{
		min-width: 100px;
		@include media($md){
			min-width: 180px;
		}
		@include media($lg){
			min-width: 300px;
		}
	}
	&--head{
		width: 190px!important;
		min-width: 190px!important;
		margin: 0 10px;
		display: none;
		height: 32px!important;
		margin-bottom: 10px;

		.button__content{
			height: 32px!important;
		}

		@include media($def){
		    display: block;
		}
	}
	&--big{
		
	}
	&--w100{
		width: 100%!important;
		min-width: 100%!important;
	}
	&:hover{
		.button__content{
			color: $c-white;
			transition: color 0.5s 0.4s ease;
		}
		&:before{
		    transform: scaleX(1);
		    transition: transform 0.6s 0.2s ease-in-out;
		}
		&:after{
			transform: scaleY(0.1);
			transition: transform 0.5s 0s ease, background-color 0.3s 0s ease, left 0.5s 0s ease;
			left: 7px;
		}
	}
	&:active{
		
	}
	&:before{
		width: calc(100% + 2px);
		height: calc(100% + 2px);
		position: absolute;
	    transform: scaleX(0);
		left: -1px;
		top: -1px;
		content: "";
		z-index: 5;
		transition: transform 0.6s 0s ease-in-out;
		transform-origin: left;
	}
	&:after{
		position: absolute;
		content: "";
		left: 50%;
		right: 0;
		width: 14px;
		height: 14px;
		bottom: -7px;
		border-radius: 100%;
		overflow: hidden;
		background-color: $c-orange;
		transform: scaleY(1);
		margin-left: -7px;
		z-index: 5;
	    transition: transform 0.4s 0.4s ease, background-color 0.3s 0.4s ease, left 0.4s 0.4s ease;
	}
	&--popup{
		color: $c-white;
		border: 1px solid $c-dblue;
		background-color: $c-blue;
		&:after,
		&:before{
			background-color: $c-white;
		}

		&:hover{
			.button__content{
				color: $c-blue;
			}
			
			
		}
	}
}

