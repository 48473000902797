.bg-holder{
	margin-right: 2vw;
	height: 90px;
	display: flex;
	align-items: center;
	position: relative;
	width: calc(100% - 2vw);
	&__right,
	&__left,
	&__center{
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto 0;
		width: 50%;
		display: none;
		@include media($psw){
			display: block;
		}
		&:after{
			position: absolute;
			content: "";
			width: 27vw;
			background-color: $c-grey2;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			height: 1px;
		}
		span{
			width: 88px;
			height: 88px;
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto 0;
			z-index: 5;
			&:after{
				position: absolute;
				content: "";
				background-color: #ececec;
				width: 1px;
				height: 100%;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			&:before{
				position: absolute;
				content: "";
				background-color: #ececec;
				height: 1px;
				width: 100%;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			i{
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				margin: auto;
				width: 11px;
				height: 11px;
				background-color: transparent;
				border-radius: 100%;
				border: 1px solid #d4d4d4;
				&:before{
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					background-color: #d8d8d8;
					width: 1px;
					height: 50px;
					content: "";
					z-index: 5;
				}
				&:after{
					position: absolute;
					left: -22px;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
					background-color: #a2a2a2;
					height: 1px;
					width: 50px;
					content: "";
					z-index: 5;
				}
			}
		}
	}
	&--abus{
		margin-bottom: 40px;
	}
	&__left{
		left: 0;
		&:after{
			left: 0;
		}
		span{
			left: 0;
		}
		&--advantages{
			right: 0;
			margin: auto;
			&:after{
				display: none;
			}
			span{
				right: 0;
				margin: auto;
				top: 80px;
				&:before,
				&:after{
					background-color: #a1a8ac;
				}
				i{
					border-color: #bfc4c7;
					&:before,
					&:after{
						background-color: $c-white;
					}
				}
			}

		}
	}
	&__center{
		right: 0;
		left: 0;
		margin: auto;
		&:after{
			right: 0;
			left: 0;
			margin: auto;
		}
		span{
			right: 0;
			left: 0;
			margin: auto;
		}
	}
	&__right{
		right: 0;
		&:after{
			right: 0;
		}
		span{
			right: 0;
		}
	}
	&--white-exp{
		.bg-holder__right span i:before, .bg-holder__left span i:before, .bg-holder__center span i:before
		.bg-holder__right span i:after, .bg-holder__left span i:after, .bg-holder__center span i:after{
			background-color: $c-white;
		}
		.bg-holder__right span:before, .bg-holder__left span:before, .bg-holder__center span:before,
		.bg-holder__right span:after, .bg-holder__left span:after, .bg-holder__center span:after{
			background-color: #2c4451;
		}
	}
	&--orange{
		.bg-holder__right,
		.bg-holder__left,
		.bg-holder__center{
			&:after{
				background-color: $c-orange;
			}
		}
	}
	&--wus{
		position: absolute;
		left: 0;
		bottom: -50px;
		margin-right: 0;
		width: 100%;
		@include media($mg){
			margin-right: 2vw;
			width: calc(100% - 2vw);
		}
	}
	&--w100{
		.bg-holder__right:after,
	.bg-holder__left:after{
			width: 100%;
		}
	}
	&--footer{
		position: absolute;
		top: 36px;
		left: 0;
		display: none;
		@include media($def){
			display: block;
		}
	}
}