.custom-cross{
	position: absolute;
	width: 	100%;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	background-color: $c-orange;
	cursor: pointer;
	z-index: 26;
	
	&:after,
	&:before{
		position: absolute;
		width: 	1px;
		height: 36px;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		background-color: $c-blue;
		content: "";
		transition: transform 0.5s ease, background-color 0.5s ease;
		z-index: 3;
	}
	&:after{
		transform: rotate(45deg);
	}
	&:before{
		transform: rotate(-45deg);
	}
	span{
		background-color: #d4af1d;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		position: absolute;	
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		z-index: 1;
		&:after,
		&:before{
			position: absolute;
			width: 	1px;
			height: 66px;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			background-color: #dab418;
			content: "";
			z-index: 2;
			transition: transform 0.5s ease, background-color 0.5s ease;
		}
		&:after{
			transform: rotate(45deg);
		}
		&:before{
			transform: rotate(-45deg);
		}
	}
	&:hover{
		&:after,
		&:before,
		span:before,
		span:after{
			background-color: $c-blue;
		}
		&:after,
		span:after{
			transform: scaleX(1.1) rotate(45deg);
		}

		&:before,
		span:before{
			transform: scaleX(1.1) rotate(-45deg);
		}
	}
}