.map-wrapper{
	height: 41.5vh;
    min-height: 260px;
    width: 100%;
    position: relative;
    z-index: 0;
    margin-bottom: 10px;
    @include media($def){
        margin-bottom: 0px;
    }
}
.map{
	width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.map-overlay{
    display: none;
    width: 100%;
    position: absolute;
    z-index: 25;
    height: 100%;
    background-color: transparent;
    cursor: pointer;
    &.is-active{
        display: block;
    }
}