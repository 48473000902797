.phones{
	position: relative;
	display: none;
	flex-wrap: nowrap;
	@include media($def){
		display: flex;
	}
	&__item{
		color: $c-blue;
		text-decoration: none;
		white-space: nowrap;
		font-size: 20px;
		display: inline-block;
		@include media($mg){
			font-size: 24px;
		}
	}
	&__arrow{
		width: 20px;
		height: 20px;
		cursor: pointer;
		position: relative;
		display: inline-block;
	    user-select: none;
	    margin: 0 6px 0 18px;
		&:after{
			content: "";
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			position: absolute;
			width: 10px;
			height: 10px;
			border-left: 1px solid $c-lblue;
			border-bottom: 1px solid $c-lblue;
			transform: rotate(-45deg);
			transition: border-color 0.5s ease;
		}
		&:hover{
			&:after{
				border-color: $c-blue;
			}
		}
	}
	&__more{
		position: absolute;
		display: block;
		padding: 0 16px;
		background-color: $c-white;
		text-align: center;
		min-width: 180px;
	    left: 0;
		top: 44px;
		max-height: 0px;
		transition: padding 0.6s ease, max-height 0.6s ease;
		overflow: hidden;
		@include media($def){
			top: 40px;
			min-width: 225px;
			left: -40px;
		}
		@include media($mg){
			top: 68px;
		}
		&.is-active{
			padding: 16px;
			max-height: 1000px;
			transition: padding 0.6s ease, max-height 2.6s ease;
		}
	}
}