////
/// @group _partials/view-text.scss
////


// scss
// ================================


.view-text {
	color: inherit;
	position: relative;
	font-size: 1rem;
	line-height: 1.5;

	&--news{
		columns: 1;
		@include media($md){
			columns: 2;
		}
		p{
			max-width: 100%!important;
		}
	}

	blockquote, & {
		> :first-child {
			margin-top: 0;
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}

// заголовки
.view-text {
	font-family: 'Proxima Nova Rg';

	h1, h2, h3, h4, h5, h6 {
		font-family: 'Bebas Neue';
		font-weight: normal;
		margin: 0;
	}

	img{

	}

	h1 {
		font-size: 38px;
		color: #899297;
		line-height: 110%;
		font-weight: 300;
		margin: 14px 0 28px;
		@include media($def){
			font-size: 48px;
		}
	}

	h2 {
		font-size: 2.25em;
	}

	h3 {
		font-size: 1.75em;
	}

	h4 {
		font-size: 1.5em;
	}

	h5 {
		font-size: 1.25em;
	}

	h6 {
		font-size: 1em;
	}
}

// основные элементы
.view-text {
	p, ul, ol, hr, dl, pre, address, blockquote, table,
	.table-wrapper, .media-wrapper {
		margin: 0;
	}

	blockquote {
		padding-left: 40px;
		font-size: 24px;
		line-height: 30px;
		color: $c-blue;
		position: relative;
		margin: 20px 0 30px 0;
		padding-top: 10px;
		max-width: 790px;
		width: 100%;
		&:after{
			position: absolute;
		    left: -14px;
    		content: '"';
    		top: 26px;
			font-size: 90px;
			line-height: 30px;
			color: $c-orange;
			font-style: italic;
			font-weight: bold;
		}
	}

	hr {
		clear: both;
		border-width: 0;
		border-top-width: 1px;
	}
	p{
		margin: 14px 0 28px;
		color: #000000;
		font-size: 14pt;
		line-height: 24px;
		font-weight: 300;
	}
	p, ul, ol{
		// max-width: 700px;
		max-width: 100%!important;
		width: 100%;
	}
}

textarea{
	padding: 16px!important;
	resize: none!important;
}

// списки
.view-text {
	ul, ol {
		padding-left: 40px;
		list-style-type: none;
		margin: 14px 0 28px;
		li{
			position: relative;
			font-size: 16px;
			line-height: 24px;
			color: $c-blue;
			font-weight: normal;
			width: 100%;
		}
	}

	ul{
		li{
			
			&:before{
				position: absolute;
				content: "";
				width: 14px;
				height: 2px;
				left: -30px;
				top: 10px;
				background-color: $c-orange;
			}
		}
	}

	ol{
		li{
			counter-increment: step-counter;
			&:before{
				position: absolute;
				content: counter(step-counter)".";
				font-weight: bold;
				color: $c-orange;
				font-size: 16px;
				top: 0;
				left: -30px;
			}
		}
	}

	dd {
		margin-left: 1.5em;
	}

	dt {
		font-weight: bold;
	}

	dd + dt {
		margin: 1em 0 0;
	}
}

// демонстрация кода
.view-text {
	width: 100%;
	pre, code, kbd, samp {
		font-style: normal;
		font-weight: normal;
		font-family: $font-family--monospace;
	}

	pre {
		tab-size: 4;
		padding: 1em 1.5em;
		background-color: #f5f5f5;
	}

	code, kbd, samp {
		padding: 0 .4em;
	}

	code {
		background-color: #f5f5f5;
	}

	kbd {
		background-color: #e1f0fa;
	}

	samp {
		background-color: #f7f8e2;
	}
}

// media элементы
.view-text {
	iframe {
		border-width: 0;
	}

	audio {
		width: 100%;
	}

	audio[controls] {
		display: block;
	}

	.media-wrapper {
		&__holder {
			background-color: #f5f5f5;
			position: relative;
			iframe, video {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
		}
	}
}

// таблицы
.view-text {
	table  {
		width: 100%;
		background-color: transparent;
		border-collapse: collapse;
		border-spacing: 0;
		border: 1px solid #ddd;
	}

	caption {
		margin-bottom: .5em;
		caption-side: top;
	}

	tbody, tfoot, thead, tr, th, td {
		border: inherit;
	}

	th, td {
	   padding: .5em;
	   text-align: left;
	   vertical-align: top;
	}

	th {
		font-weight: bold;
		text-align: center;
		background-color: #f5f5f5;
	}
}

// таблица "без границ"
.view-text .table-null {
	&, th, td {
		border-color: transparent;
	}

	th {
		background: transparent;
	}
}

// таблица "зеброй"
.view-text .table-zebra {
	&, th, td {
		border-color: transparent;
	}

	th {
		background: transparent;
	}

	tr:nth-child(odd) {
		background-color: #f5f5f5;
	}
}

// ссылки
.view-text {
	a {
		color: #43f;
		&:visited {
			color: #0e00ad;
		}
		&:hover {
			color: #f34;
		}
		&:active {
			color: #000;
		}
	}
}

// форматирование и акцентирование
.view-text {
	b, strong {
		font-weight: bold;
	}

	i, cite, em, var, address, dfn, caption {
		font-style: italic;
	}

	abbr[title], dfn[title] {
		text-decoration: none;
		border-bottom: 1px dotted;
		cursor: help;
	}
}

// выход элементов за границы
// отступы должны совпадать с отступами .view-size
.view-text {
	@include media( $def, max ) {
		> blockquote,
		> pre,
		> .media-wrapper,
		> video,
		> iframe
		{
		}

		> blockquote,
		> pre
		{
		}
	}
}

// текст с короткой длиной строки
.view-text--article {
	> p,
	> h1,
	> h2,
	> h3,
	> h4,
	> h5,
	> h6,
	//> blockquote,
	//> pre,
	> hr,
	> ul,
	> ol,
	> dl,
	> audio,
	> address
	{
		max-width: $def;
		margin-left: auto;
		margin-right: auto;
	}
}

.image-content-wrapper{
	position: relative;
	line-height: 0;
	width: 100%;
	max-width: 100%!important;
	img{
		position: relative;
		z-index: 2;
	}
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: "";
		background-image: url("../images/dots2.png");
	}
}