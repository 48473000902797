.news-grid{
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}
.news-item{
	position: relative;
	height: auto;
	margin-bottom: 20px;
	display: flex;
	text-decoration: none;
	cursor: pointer;
	z-index: 1;
	flex-direction: column;
	@include media($psh){
		height: 248px;
		flex-direction: row;
	}
	&--more-exp{
		.news-item__title{
			font-size: 16px;
			b{
				max-height: 18px;
				overflow: hidden;
			}
			@include media($def){
				font-size: 20px;
				b{
					max-height: 22px;
					overflow: hidden;
				}
			}
			@include media($xl){
				font-size: 22px;
				b{
					max-height: 24px;
					overflow: hidden;
				}
			}
			@include media($mg){
				font-size: 24px;
				b{
					max-height: 26px;
					overflow: hidden;
				}
			}
		}
	}
	&__image{
		background-repeat: no-repeat;
		background-position: 50%;
		background-size: cover;
		width: 100%;
		max-width: 420px;
		height: 248px;
		margin: 0 auto;
		@include media($psh){
			height: 100%;
			width: 420px;
			margin: 0;
		}
	}
	&__title{
		color: $c-lblue;
		font-size: 20px;
		line-height: 110%;
		font-weight: 300;
		letter-spacing: 0.1em;
		font-family: 'Bebas Neue';
		transition: color 0.5s ease;
		margin-bottom: 20px;
		@include media($psh){
			margin-bottom: 0;
		}
		@include media($def){
			font-size: 24px;

		}
		@include media($xl){
			font-size: 26px;
		}
		@include media($mg){
			font-size: 30px;
		}
		b{
			display: block;
		}
	}
	&__date{
		font-size: 14px;
		font-family: 'MagistralC';
		color: $c-orange;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		&--news-page{
			margin-bottom: rem(28);
			font-weight: bold;
		}
	}
	&__button{
		position: absolute;
		right: 0;
		bottom: 30px;
		font-family: 'Bebas Neue';
		font-size: 20px;
		color: #2b3c90;
		line-height: 100%;
		letter-spacing: 0.05em;
		padding: 4px 64px 4px 10px;
		background-color: $c-white;
		text-decoration: none;
		transition: background-color 0.5s ease, color 0.5s ease;
		@include media($psh){
			font-size: 22px;
		}
		@include media($def){
			font-size: 24px;
		}
	}
	&__descr{
		position: relative;
		padding-left: 0;
		padding-top: 16px;
		padding-bottom: 40px;
		padding-right: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background-color: #f8f8f8;
		transition: background-color 0.5s ease;
		width: 100%;
		@include media($psh){
			padding-left: 66px;
			padding-right: 32px;
			padding-top: 70px;
		}

		.bg-holder__left{
			position: absolute;
			left: 0;
			top: 0;
			display: none;
			@include media($psh){
				display: block;
			}
		}
		.bg-holder__left:after{
			display: none;
		}
		.bg-holder__right span, .bg-holder__left span, .bg-holder__center span{
			margin: 0 0 0 auto;
		}
	}
	&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		    box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
		    opacity: 0;
		    transition: opacity 0.5s ease;
		    content: "";
		}
	&:hover{
		z-index: 2;
		&:after{
			opacity: 0;
			@include media($psh){
				opacity: 1;
			}
		}
		.news-item{
			&__title{
				color: $c-blue;
			}
			&__button{
				color: $c-white;
				background-color: $c-blue;
			}
			&__descr{
				background-color: $c-white;
			}
		}
	}
}