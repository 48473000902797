////
/// @group _partials/view-footer.scss
////


// scss
// ================================

.view-footer {
	position: relative;
	margin-left: 0;
	padding-bottom: 20px;
	@include media($def){
		height: auto;
		margin-left: 200px;
	}
	@include media($mg){
		margin-left: 280px;
	}
	&__bottom{
		max-width: 1480px;
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 0;
		flex-direction: column;
		position: relative;
		z-index: 25;
		@include media($def){
			padding-top: 16px;
			padding-right: 34px;
			flex-direction: row;
			justify-content: space-between;
		}
		.logo-block{
			height: 92px;
		}
	}
	&__404{
		position: relative;
		bottom: 0;
		width: 100%;
		left: 0;
		@include media($r1023){
			position: fixed;
		}
		@include media($def){
			width: calc(100% - 200px);
		}
		@include media($lg){
			width: calc(100% - 280px);
		}
		.main-nav__item{
			display: none;
		}
	}
	&__group{
		display: flex;
		align-items: center;
		align-content: center;
		flex-direction: column;
		margin-bottom: 20px;
		@include media($def){
			margin-bottom: 0;
			flex-direction: row;
		}
	}
}
.copy{
	padding-left: 8px;
	color: $c-lblue;
	font-size: 12px;
	font-family: 'Proxima Nova Rg';
}
@media (min-width: 1700){
	.view-footer__bottom{
		padding-top: 44px;
	}
}