.search{
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
    height: 50px;
    width: 50px;
    z-index: 26;
    @include media($def){
    	height: 68px;
    	width: 68px;
	}
	@include media($xl){
    	height: 73px;
    	width: 73px;
	}
	@include media($mg){
		height: 90px;
		width: 90px;
	}
	svg{
		width: 26px;
		height: 26px;
		fill: $c-lblue;
		transition: fill 0.5s ease;
	}
	&:hover{
		svg{
			fill: $c-orange;
		}
	}
}
.search-block{
	position: relative;
	overflow: hidden;
	z-index: 26;
	.custom-cross{
		transform: scaleX(0);
		transform-origin: right;
		transition: transform 0.3s ease-out;
	}
	&.is-active{
		.custom-cross{
			transform: scaleX(1);
		}
	}
}
.search-form{
	position: fixed;
	margin-right: 0;
	background-color: #7f6600;
	padding: 0 10px;
	height: 50px;
	display: flex;
	align-items: center;
	z-index: 25;
	transform-origin: right;
	transform: translateX(200%) scaleX(0.2);
	opacity: 0;
	top: 64px;
	right: 0;
	transition: transform 0.5s 0.2s cubic-bezier(.76,-0.35,.55,.88), opacity 1.5s 0.1s ease;
	@include media($def){
    	height: 68px;
    	position: absolute;
    	top: 0;
    	right: calc(100% - 1vw);
    	margin-right: 14px;
	}
	@include media($xl){
    	height: 73px;
    	padding: 0 10px;
	}
	@include media($mg){
		right: calc(100% - 2.4vw);
		margin-right: 1px;
		height: 90px;
		padding: 0 20px;
	}
	.control-holder{
		width: 100%;
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	.form__group{
		width: 100%;
		padding-right: 10px;
		@include media($mg){
			padding-right: 20px;
		}
	}
	.button{
		opacity: 0;
		transition: opacity 0.3s ease;
	}
	&.is-active{
		transform: scale(1);
		opacity: 1;
		transition: transform 0.3s 0.2s ease, opacity 0.3s 0.3s ease;
		.control-holder{
			opacity: 1;
			transition: opacity 0.4s 0.5s ease;
		}
		.button{
			opacity: 1;
			transition: opacity 0.4s 0.5s ease;
		}
	}
}