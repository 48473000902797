.wrk-content{
	padding: 40px 0;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: cover;
	margin-bottom: 20px;
	@include media($def){
		margin-bottom: 60px;
	}

	&__grid{
		display: flex;
		justify-content: center;
		padding: 0 30px;
		flex-wrap: wrap;
		@include media($xl){
			flex-wrap: nowrap;
		}
	}
}
.wrk-item{
	width: 200px;
	margin-right: 14px;
	margin-bottom: 20px;
	@include media($xl){
		margin-bottom: 30px;
	}
	@include media($mg){
		margin-right: 22px;
	}
	&__top{
		display: flex;
		align-items: flex-end;
		margin-bottom: 40px;
		position: relative;
		height: 82px;
		&:before,
		&:after{
			position: absolute;
			content: "";
			background-color: $c-orange;
		}
		&:before{
			left: -8px;
			width: 14px;
			height: 14px;
			border-radius: 100%;
			bottom: -27px;
		}
		&:after{
			left: 0;
			width: 100%;
			height: 1px;
			bottom: -20px;
		}
	}
	&__image{
		svg{
			fill: #8d9fa9;
			max-width: 100%;
			max-height: 100%;
		}
	}
	&__title,
	&__time{
		color: $c-white;
		font-size: 22px;
		line-height: 100%;
		@include media($def){
			font-size: 26px;
		}
		@include media($mg){
			font-size: 30px;
		}
	}
	&__time{
		font-weight: 300;
		margin-bottom: 10px;
	}
	&__text{
		font-weight: 300;
		font-size: 14px;
		line-height: 1.6em;
		color: $c-white;
		@include media($mg){
			font-size: 16px;
		}
	}
	&__number{
		font-size: 70px;
		color: $c-orange;
		line-height: 50px;
		margin-right: 24px;
	}
	&:nth-child(1){
		.wrk-item__image{
			@include media($md){
			width: 80px;
			height: 80px;
			}
				width: 66px;
				height: 66px;
		}
	}
	&:nth-child(2){
		.wrk-item__image{
			@include media($md){
			width: 80px;
			height: 80px;
			}
				width: 66px;
				height: 66px;
		}
	}
	&:nth-child(3){
		.wrk-item__image{
			@include media($md){
			width: 78px;
			height: 78px;
			}
				width: 64px;
				height: 64px;
		}
	}
	&:nth-child(4){
		.wrk-item__image{
			@include media($md){
			width: 80px;
			height: 66px;
			}
				width: 66px;
				height: 40px;
		}
	}
	&:nth-child(5){
		.wrk-item__image{
			@include media($md){
			width: 80px;
			height: 80px;
			}
				width: 66px;
				height: 66px;
		}
	}
	&:nth-child(6){
		.wrk-item__image{
			@include media($md){
			width: 80px;
			height: 80px;
			}
				width: 66px;
				height: 66px;
		}
	}
	&:nth-child(7){
		margin-right: 0;
		.wrk-item__image{
			@include media($md){
			width: 80px;
			height: 80px;
			}
				width: 66px;
				height: 66px;
		}
	}
}