
.sub-nav{
	list-style-type: none;
	padding: 0;
	margin: 0;
	padding-top: 20px;
	transition: max-height 1.5s ease;
	overflow-y: scroll;
	&.js-halper{
		transition: max-height 0.5s ease;
		margin-bottom: 20px;
	}
	&__item{
		padding: 0;
		margin-bottom: 20px;
		@include media($mg){
			padding: 0 40px;
			margin-bottom: 30px;
		}
	}
	&__link{
		display: block;
		text-align: center;
		text-decoration: none;
		color: $c-lblue;
		font-size: 18px;
		transition: color 0.5s ease;
		letter-spacing: 0.1em;
		&:hover{
			color: $c-blue;
			.sub-nav__link-image{
				svg{
					fill: $c-blue;
				}
			}
			.sub-nav__link-tex{
				&:after{
					background-color: $c-blue;
				}
			}
		}
	}
	&__link-image{
		width: 70px;
		height: 68px;
		position: relative;
		display: block;
		margin: 0 auto;
		margin-bottom: 4px;
		svg{
			max-width: 100%;
			transition: fill 0.5s ease;
			max-height: 100%;
			fill: $c-lblue;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
		}
	}
	&__link-text{
		display: inline-block;
		position: relative;
		font-weight: bold;
		padding-bottom: 2px;
		text-transform: uppercase;
		&:after{
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			background-color: $c-lblue;
			width: 100%;
			transition: background-color 0.5s ease;
			height: 1px;
		}
	}
}