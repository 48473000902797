////
/// @group style.scss
////


// Config
// ================================
	@import 'config';


// Фикс mqpacker
// ================================
	@include mqPacker;


// Normalize
// ================================

	@include normalize__document;
	@include normalize__sections;
	@include normalize__grouping-content;
	@include normalize__text-level-semantics;
	@include normalize__embedded-content;
	@include normalize__forms;
	@include normalize__interactive;
	@include normalize__scripting;
	@include normalize__hidden;

	@include normalize__text-level-semantics__psevdo-classes;
	@include normalize__forms__psevdo-classes;
	@include normalize__addons;


// Import Partials
// ================================
	// основа
	@import '_partials/base';

	// структура
	@import '_partials/view-wrapper';
	@import '_partials/view-size';
	@import '_partials/view-section';
	@import '_partials/view-header';
	@import '_partials/view-footer';

	// hidden
	@import '_partials/mfp-zoom-in';
	@import '_partials/hidden-wrapper';

	// контент
	@import '_partials/view-text';
	@import '_partials/table-wrapper';
	@import '_partials/content-image';
	@import '_partials/title';

	// ui
	@import '_partials/breadcrumbs';
	@import '_partials/sitemap';
	//@import '_partials/buttons';
	@import '_partials/buttons-default';
	@import '_partials/pagination.scss';
	@import '_partials/form';
	@import '_partials/fPopUp';


// Grid
// // ================================
// 	@include grid-full;


// // Include Utils
// // ================================
// 	@include util-colors;
// 	@include util-bgcolors;
// 	@include util-fills;

// 	@include util-floats;
// 	@include util-floats-mq;

// 	@include util-texts;
// 	@include util-texts-mq;

// 	@include util-visibility;
// 	@include util-visibility-mq;

// 	@include util-gutters;
// 	@include util-gutters-mq;


// Custom css
// ================================


@import '_partials/_logo';
@import '_partials/_text';
@import '_partials/_map';
@import '_partials/_wus';
@import '_partials/_news';
@import '_partials/_partners';
@import '_partials/_seo';
@import '_partials/_sub-contacts';
@import '_partials/_wezom';
@import '_partials/_aside';
@import '_partials/_fonts'; 
@import '_partials/_contacts-slyles'; 
@import '_partials/_objects'; 
@import '_partials/_advantages'; 
@import '_partials/_phones';
@import '_partials/_to-top-arrow';
@import '_partials/_search';
@import '_partials/_sub-nav';
@import '_partials/_main-block';
@import '_partials/_soc';
@import '_partials/_slider-parts';
@import '_partials/_wrk';
@import '_partials/_buttons';
@import '_partials/_hamburger';
@import '_partials/_bg-holder';
@import '_partials/_custom-scroll';
@import '_partials/_main-nav';
@import '_partials/_halpers';
@import '_partials/_custom';

.soc-block__link--yt:hover:after{
	background-color: #e40606;
}
.mnew-group{
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.idgreed{
	display: flex;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	margin-bottom: 20px;
	@include media($mg){
		padding-left: 50px;
		padding-right: 120px;
	}
}
.right-side{
	width: 100%;
	margin-left: 0;

	@include media($r1023){
		width: calc(100% - 360px);
		margin-left: 20px;
	}
	@include media($xl){
		width: calc(100% - 400px);
		margin-left: 40px;
	}
	@include media($mg){
		margin-left: 56px;
		width: calc(100% - 520px);
	}
}
.js-form-file__input{
	display: none;
}
.left-panel{
	width: 360px;
	display: none;
	flex-direction: column;
	background-image: url("../images/dot.png");

	
	@include media($r1023){
		display: flex;
	}
	@include media($xl){
		width: 400px;
	}
	@include media($mg){
		width: 414px;
		// width: 480px;
	}
}
.jbg{

}
.addthis-widget{
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	width: 100%;
	position: relative;
	z-index: 5;
}
.more-news-block{
	background-color: $c-white;
}
.left-panel-halper{

}
.left-nav{
	list-style-type: none;
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: rem(40);
	background-color: $c-white;
	&__button-wrapper{
		padding-bottom: 30px;
		background-color: $c-white;
	}
	&__item{
		padding: 0;
		margin: 0;
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 10px;
	}
	&__link{
		display: block;
		width: 100%;
		background-color: #f8f8f8;
		border: 1px solid #f8f8f8;
		font-size: 24px;
		letter-spacing: 0.1em;
		color: $c-lblue;
		text-decoration: none;
		text-transform: uppercase;
		font-family: 'Bebas Neue';
		font-weight: bold;
		padding: 0 90px 0 30px;
		min-height: 92px;
		height: initial;
		display: flex;
		align-items: center;
		align-content: center;
		transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
		// line-height: 90px;
		// span{
		// 	display: inline-block;
		// 	vertical-align: middle;
		// 	line-height: 100%;
		// 	text-align: center;
		// }
		.slider-arrow{
			background-color: #f8f8f8;
			top: 1px;
			right: 1px;
			transition: background-color 0.5s ease;
		}
		.slider-arrow:hover:before {
		    transform: scaleY(0);
		}
		.slider-arrow:hover .arrow-image-wrapper__image-part-2 {
		    background-color: #cacede;
		}
		.slider-arrow .arrow-image-wrapper__image-part-1 {
		    border-color: #0b3b54;
		}
		.slider-arrow .arrow-image-wrapper__image-part-1:after {
		    background-color: #0b3b54;
		}
		.slider-arrow:before{
			transform: scaleX(0);
			transform-origin: right;
		}
		&.is-opened{
			.slider-arrow{
		    	transform: rotate(90deg);
			}
		}
		&:hover,
		&.is-active{
			color: $c-blue;
			background-color: $c-white;
			border-color: $c-orange;
			.slider-arrow:before {
			    transform: scaleX(1);
			}
			.slider-arrow{
				background-color: $c-white;
			}
			.slider-arrow .arrow-image-wrapper__image-part-2 {
			    background-color: #d4af1d;
			}
		}
		&.is-active{
			pointer-events: none;
		}
	}
}
.left-sub-nav{
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	&__item{
		display: block;
	}
	&__link{
		height: 72px;
		position: relative;
		border-bottom: 1px solid $c-orange;
		border-right: 1px solid $c-orange;
		border-left: 1px solid $c-orange;
		display: flex;
		align-items: center;
		align-content: center;
		padding-left: 48px;
		text-decoration: none;
		background-color: #f8f8f8;
		color: $c-lblue;
		padding-right: 60px;
		font-family: 'Bebas Neue';
		text-transform: uppercase;
		font-size: 18px;
		font-weight: bold;

		&.is-active{
			background-color: $c-orange;
			color: $c-blue;
			pointer-events: none;
			&:after{
				background-color: #d4af1d;
			}
		}
		&:after{
			position: absolute;
			content: "";
			top: 0;
			bottom: 0;
			margin: auto 0;
			right: 39px;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			background-color: #cfd2e3;
		}
		&:hover{
			color: $c-blue;
			background-color: $c-white;
		}
	}
}
.items-grid,
.videos-grid{
	display: flex;
	flex-wrap: wrap;
	width: 100%
}
.item{
	margin-right: 0;
	margin-bottom: 18px;
	width: 100%;
	cursor: pointer;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	max-height: 350px;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 455px;
	align-content: center;
	@include media($sm){
		width: calc(50% - 18px);
		margin-right: 18px;
	}
	&:after{
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.5;
		background-color: #14384b;
		z-index: 2;
	}
	&__image{
		position: relative;
		z-index: 1;
		transition: transform 1s ease;
	}
	&__title{
		position: absolute;
		z-index: 3;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		text-align: center;
		font-size: 22px;
		line-height: 100%;
		color: $c-white;
		flex-direction: column;
		font-weight: 300;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		font-family: 'Bebas Neue';
		transition: color 0.5s ease;
		@include media($def){
			font-size: 24px;
		}
		@include media($xl){
			font-size: 28px;
		}
		@include media($mg){
			font-size: 30px;
		}
		b{
			display: block;
		}
	}
	&:hover{
		.item__title{
			color: $c-orange;
		}
		.item__image{
			transform: scale(1.1);
		}
	}
}
.single-block{

}
.videos-grid{
	justify-content: center;
	margin-bottom: rem(50);
	@include media($xl){
		justify-content: flex-start;
	}
}
.video-item{
	margin-right: 0;
	margin-bottom: 18px;
	width: 100%;
	cursor: pointer;
	text-decoration: none;
	position: relative;
	overflow: hidden;
	max-width: 460px;
	@include media($psh){
		width: calc(50% - 18px);
		margin-right: 18px;
	}
	@include media($xl){
		width: calc(33.33% - 18px);
		margin-right: 18px;
	}
	&--f{
		cursor: default;
		display: none;
		&:after{
			display: none;
		}
		@include media($sm){
			display: block;
		}
	}
	&__image{
		position: relative;
		z-index: 1;
		transition: transform 1s ease;
	}
	&__title-image{
		display: flex;
		margin-bottom: 28px;
		padding-top: 60px;
		svg{
			width: 52px;
			height: 40px;
			fill: $c-white;
			transition: fill 0.5s ease;
		}
	}
	&__title{
		position: absolute;
		z-index: 3;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		text-align: center;
		font-size: 22px;
		line-height: 100%;
		color: $c-white;
		flex-direction: column;
		font-weight: 300;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		font-family: 'Bebas Neue';
		transition: color 0.5s ease;
		@include media($def){
			font-size: 24px;
		}
		@include media($xl){
			font-size: 28px;
		}
		@include media($mg){
			font-size: 30px;
		}
		b{
			display: block;
		}
	}
	&:after{
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0.5;
		background-color: #14384b;
		z-index: 2;
	}
	&:hover{
		.video__title{
			color: $c-orange;
		}
		.video__title-image{
			svg{
				fill: $c-orange;
			}
		}
		.video__image{
			transform: scale(1.1);
		}
	}
}
.items-slider{
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 46px;
	&__main-wrapper{
		position: relative;
		margin-bottom: 20px;
		max-width: 940px;
		width: 100%
	}
	&__main{
		width: 100%
	}
	&__main-item{
		height: 370px;
		border-right: none;
		background-position: 50%;
		background-size: cover;
	}
	&__thumbs-item{
		cursor: pointer;
	}
	.slider-arrow-group{
		bottom: 1px;
		right: 1px;
	}
}
.item-descr{
	&__table{
		width: 100%;
		display: flex;
		max-width: 936px;
		flex-direction: column;
	}
	&__table-row{
		display: flex;
		flex-direction: column;
		@include media($r1023){
			flex-direction: row;
		}
		&:nth-child(1){
			.item-descr__table-D{
				border-top: 1px solid $c-orange;
			}
		}
		.item-descr__table-D{
			&:nth-child(1){
				font-weight: bold;
				color: $c-blue;
				width: 100%;
				@include media($r1023){
					width: 25%;
				}
			}
			&:nth-child(2){
				color: $c-lblue;
				width: 100%;
				margin-left: 0;
				font-weight: normal;
				padding-right: 10%;
				@include media($r1023){
					width: 75%;
					margin-left: 34px;
				}
				@include media($mg){
					padding-right: 0;
				}
			}
		}
	}
	&__table-D{
		font-size: 16px;
		line-height: 50px;
		font-family: 'MagistralC';
		padding: 0;
		border-bottom: 1px solid $c-orange;
	}
}
.news-page-image{
	position: relative; 
	width: 100%;
	max-height: 418px;
	margin-bottom: rem(38);
	img{
		max-width: 710px;
		max-height: 418px;
		position: relative;
		z-index: 2;
		width: 100%;
	}
	&:after{
		position: absolute;
		left: -104px;
		top: 0;
		width: calc(100% + 104px);
		content: "";
		height: 100%;
		z-index: 1;
		background-image: url(../images/dot.png);
	}
}
.inner-news{
	&__bottom-row{
		display: flex;
		justify-content: space-between;
		height: 90px;
		align-content: center;
		margin-bottom: rem(30);
		align-items: center;
	}
	&__to-left,
	&__to-right{
		color: #6a73aa;
		text-decoration: none;
		display: block;
		outline: none;
		text-transform: uppercase;
		font-family: 'Bebas Neue';
		font-size: 24px;
		height: 90px;
		display: flex;
		align-items: center;
		align-content: center;

		.slider-arrow{
			position: relative;
		}
		&:hover{
			.slider-arrow:before {
			    transform: scaleY(1);
			}
			.slider-arrow .arrow-image-wrapper__image-part-1 {
			    border-color: #0b3b54;
			}
			.slider-arrow .arrow-image-wrapper__image-part-1:after {
			    background-color: #0b3b54;
			}
			.slider-arrow .arrow-image-wrapper__image-part-2 {
			    background-color: #d4af1d;
			}
			.slider-arrow .arrow-image-wrapper__image-part-2:after {
				    border-color: #d4af1d;
				}
		}
	}
	&__text{
		display: none;
		@include media($r1023){
			display: block;
		}
	}
	&__to-left{
		.inner-news__text{
			padding-left: 20px;
			@include media($xl){
				padding-left: rem(40);
			}
		}
	}
	&__to-right{
		.slider-arrow{
			order: 2;
		}
		.inner-news__text{
			order: 1;
			padding-right: 20px;
			@include media($xl){
				padding-right: rem(40);
			}
		}
	}
	&__back-button{
		height: 40px;
		width: 300px;
		background-color: $c-orange;
		text-decoration: none;
		display: block;
		outline: none;
		text-transform: uppercase;
		font-family: 'Bebas Neue';
		font-size: 18px;
		color: $c-white;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		@include media($r1023){
			height: 90px;
			font-size: 24px;
			width: 320px;
		}
		@include media($xl){
			width: 390px;
		}
		&:hover{
			color: $c-blue;
		}
	}
}
.presentations-grid{
	display: flex;
	flex-wrap: wrap;
}
.presentation-item{
	position: relative;
	height: auto;
	width: 100%;
	margin-bottom: 20px;
	display: flex;
	background-color: #f8f8f8;
	transition: background-color 0.5s ease;
	flex-direction: column;
	@include media($psw){
		flex-direction: row;
		height: 248px;
	}
	@include media($r1023){
		width: calc(50% - 10px);
	}
	&--null{
		background-image: url(../images/dot.png);
		pointer-events: none;
		display: none;
		@include media($r1023){
			display: block;
		}
	}
	&:after{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	    box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
	    opacity: 0;
	    transition: opacity 0.5s ease;
	    content: "";
	}
	&:nth-child(odd){
		margin-right: 0;
		@include media($r1023){
			margin-right: 20px;
		}
	}
	&__image{
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 120px;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		background-color: transparent;
		@include media($psw){
			width: 120px;
			height: 100%;
		}
		svg{
			fill: $c-white;
			width: 54px;
			height: 54px;
			position: relative;
			z-index: 2;
			margin-bottom: 0;
			@include media($psw){
				margin-bottom: 54px;
			}
		}
		&:after{
			width: 120%;
			height: 120%;
			background-color: #899297;
			position: absolute;
			left: -33%;
			top: 0;
			content: "";
			z-index: 1;
		    transform: skew(-6deg);
		    transition: background-color 0.5s ease;
		}
	}
	&__title{
		color: $c-lblue;
		font-size: 20px;
		line-height: 110%;
		font-weight: 300;
		letter-spacing: 0.1em;
		font-family: 'Bebas Neue';
		transition: color 0.5s ease;
		margin-bottom: 20px;
		@include media($psh){
			margin-bottom: 0;
		}
		@include media($def){
			font-size: 24px;
		}
		@include media($xl){
			font-size: 26px;
		}
		@include media($mg){
			font-size: 30px;
		}
		b{
			display: block;
		}
	}
	&__format{
		color: $c-orange;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: bold;
		font-family: 'MagistralC';
	}
	&__descr{
		position: relative;
		height: 100%;
		padding-top: 20px;
		padding-left: 10px;
		padding-bottom: 40px;
		padding-right: 10px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: space-between;
		width: 100%;
		@include media($xs){
			padding-top: 60px;
			padding-left: 60px;
		}
		@include media($psw){
			width: calc(100% - 120px);
		}
		@include media($xl){
			padding-top: 70px;
			padding-left: 70px;
			padding-right: 20px;
		}
		.bg-holder__left{
			position: absolute;
			left: 0;
			top: 0;
			display: none;
			@include media($psh){
				display: block;
			}
		}
		.bg-holder__left:after{
			display: none;
		}
		.bg-holder__right span, .bg-holder__left span, .bg-holder__center span{
			margin: 0 0 0 auto;
		}
	}
	.news-item__button{
		z-index: 15;
	}
	.bg-holder__left{
		display: none;
		@include media($xs){
			display: block;
		}
	}
	&:hover{
		z-index: 2;
		background-color: $c-white;
		.presentation-item__image{
			&:after{
				background-color: $c-orange;
			}
		}

		&:after{
			opacity: 0;
			@include media($psh){
				opacity: 1;
			}
		}
		.news-item__button{
				color: $c-white;
				background-color: $c-blue;
			}
		.presentation-item{
			&__title{
				color: $c-blue;
			}
		}
	}
}
.m-menu__link{
	font-size: 18px;
	text-transform: uppercase;
	text-decoration: none;
	color: $c-blue;
	font-family: 'Bebas Neue';
	letter-spacing: 0.1em;
}
.finding-items{
	color: $c-orange;
	margin-bottom: rem(24);
	font-size: 24px;
	font-family: 'MagistralC';
}
#m_menu{
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	text-decoration: none;
	color: $c-blue;
	font-family: 'Bebas Neue';
	button{
		height: 	100%;
		.button__content{
			background-color: $c-blue;
			height: 	100%;
			color: $c-white;
			padding: 0;
		}
		&:after{
			display: none;	
		}
	}
}
.mm-navbar-bottom{
	display: flex;
	justify-content: space-around;
}
.search-item{
	position: relative;
	height: auto;
	margin-bottom: 20px;
	display: flex;
	text-decoration: none;
	cursor: pointer;
	z-index: 1;
	flex-direction: column;
	padding-top: 20px;
	padding-left: 10px;
	padding-bottom: 30px;
	padding-right: 10px;
	background-color: #f8f8f8;
	transition: background-color 0.5s ease;
	justify-content: space-between;	
	height: 246px;

	@include media($psh){
		padding-top: 70px;
		padding-left: 70px;
		padding-bottom: 34px;
		padding-right: 20px;
		height: 300px;
	}
	@include media($r1023){
		padding-top: 70px;
		padding-left: 70px;
		padding-bottom: 34px;
		padding-right: 170px;
	}

	&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		    box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
		    opacity: 0;
		    transition: opacity 0.5s ease;
		    content: "";
		}

	.bg-holder__left{
			position: absolute;
			left: 0;
			top: 0;
			display: none;
			@include media($psh){
				display: block;
			}
		}
		.bg-holder__left:after{
			display: none;
		}
		.bg-holder__right span, .bg-holder__left span, .bg-holder__center span{
			margin: 0 0 0 auto;
		}
	&__text{
		font-size: 16px;
		color: #7d7d7d;
		line-height: 24px;
		font-family: 'Proxima Nova Rg';
		font-weight: 300;
		margin-bottom: auto;
	    height: 72px;
    	overflow: hidden;
	}
	&__type{
		text-transform: uppercase;
		font-size: 14px;
		color: $c-orange;
		font-family: 'MagistralC';
		font-weight: bold;
	}
	&__title{
		color: $c-lblue;
		font-size: 24px;
		line-height: 30px;
		letter-spacing: 0.1em;
		font-weight: bold;
		font-family: 'Bebas Neue';
		margin-bottom: 20px;
		max-height: 60px;
		overflow: hidden;
		transition: color 0.5s ease;
		@include media($def){
			font-size: 30px;
			line-height: 36px;
		}
	}
	&:hover{
		z-index: 2;
		background-color: $c-white;
		&:after{
			opacity: 0;
			@include media($psh){
				opacity: 1;
			}
		}
		.news-item{
			&__button{
				color: $c-white;
				background-color: $c-blue;
			}
		}
		.search-item{
			&__title{
				color: $c-blue;
			}
		}
	}
}
.search-grid{
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}
.page404-content{
	min-height: auto;
	width: 100%;
	position: relative;
	left: 0;
	top: 0;
	z-index: 25;
	justify-content: center;
	flex-direction: column;
	display: flex;
	padding-left: 10px;
	padding-top: 60px;
	padding-right: 10px;
	padding-bottom: 60px;
	@include media($r1023){
		min-height: calc(100vh - 260px);
		padding-left: 288px;
		padding-top: 0;
		top: 110px;
		position: fixed;
		padding-bottom: 0;
	}
	&__title{
		color: $c-orange;
		font-size: 38px;
		line-height: 100%;
		letter-spacing: 0.1em;
		font-weight: bold;
		font-family: 'Bebas Neue';
		margin-bottom: 40px;
		@include media($def){
			font-size: 48px;
		}
	}
	&__text{
		font-size: 20px;
		color: $c-white;
		line-height: 30px;
		font-family: 'MagistralC';
		margin-bottom: 50px;
		@include media($def){
			font-size: 24px;
		}
	}
	&__link{
		letter-spacing: 0.05em;
		font-size: 20px;
		line-height: 30px;
		color: $c-white;
		font-weight: bold;
		text-transform: uppercase;
		font-family: 'Bebas Neue';
		@include media($def){
			font-size: 24px;
		}
	}
	&__link-wrapper{
		margin-bottom: 10px;
	}
}
.popup-form{
	background-color: $c-orange;
	padding: 38px 10px 50px;
    margin-top: 90px;
    @include media($lg){
    	padding: 38px 38px 50px;
    }
	&__title{
		font-size: 40px;
		font-weight: bold;
		line-height: 36px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		margin: 0;
		font-family: 'Bebas Neue';
		color: $c-blue;
		margin-bottom: 30px;
		@include media($xl){
			font-size: 48px;
			letter-spacing: 0.1em;
		}
	}
	&__subtitle{
		font-size: 24px;
		font-weight: bold;
		color: $c-poo;
		font-family: 'MagistralC';
		margin-bottom: 10px;
	}
	&__group{
		margin-bottom: 30px;
		@include media($xl){
			margin-bottom: 50px;
		}
	}
	.control-holder{
		margin-bottom: 12px;
	}
	&__button-wrapper{
		display: flex;
		justify-content: center;
	}
}
.form-group{
	&__row{
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		@include media($lg){
			flex-wrap: nowrap;
		}
	}
	&__end-value-block{
		background-color: $c-poo;
		display: flex;
	    align-items: flex-end;
	    justify-content: space-between;
	    padding: 5px 9px;
	    @include media($lg){
	        padding: 16px 10px;
	    }
	}
	&__end-value{
		font-size: 18px;
		font-family: 'MagistralC';
		color: $c-orange;
		@include media($lg){
			font-size: 24px;
		}
		span{
			font-weight: bold;
			font-size: 24px;
			@include media($lg){
				font-size: 30px;
			}
		}
	}
}
.form-toggler{
	&__name{
		font-weight: bold;
		color: $c-orange;
		font-size: 14px;
		line-height: 26px;
		height: 100%;
		font-family: 'MagistralC';
		padding-right: 10px;
		@include media($sm){
			font-size: 18px;
		}
		@include media($lg){
			font-size: 21px;
			line-height: 36px;
		}
	}
	
}
.hidden-checkbox{
	display: none;
}
.mfp-close{
	display: none!important;
}
.custom-mfp-close{
	position: absolute;
	width: 	90px;
	height: 90px;    
	top: -92px;
	right: 0;
	background-color: $c-orange;
	cursor: pointer;
	z-index: 26;
	
	&:after,
	&:before{
		position: absolute;
		width: 	1px;
		height: 36px;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		background-color: $c-blue;
		content: "";
		transition: transform 0.5s ease, background-color 0.5s ease;
		z-index: 3;
	}
	&:after{
		transform: rotate(45deg);
	}
	&:before{
		transform: rotate(-45deg);
	}
	span{
		background-color: #d4af1d;
		width: 12px;
		height: 12px;
		border-radius: 100%;
		position: absolute;	
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		z-index: 1;
		&:after,
		&:before{
			position: absolute;
			width: 	1px;
			height: 66px;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			background-color: #dab418;
			content: "";
			z-index: 2;
			transition: transform 0.5s ease, background-color 0.5s ease;
		}
		&:after{
			transform: rotate(45deg);
		}
		&:before{
			transform: rotate(-45deg);
		}
	}
	&:hover{
		&:after,
		&:before,
		span:before,
		span:after{
			background-color: $c-blue;
		}
		&:after,
		span:after{
			transform: scaleX(1.1) rotate(45deg);
		}

		&:before,
		span:before{
			transform: scaleX(1.1) rotate(-45deg);
		}
	}
}
.idk-grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.idk-item{
	width: calc(33.33% - 20px);
	margin: 10px;
	height: 400px;
	background-color: $c-grey;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	cursor: pointer;
	overflow: hidden;
	min-width: 240px;
	img{
		max-width: 100%;
		max-height: 100%;
		transition: transform 1.5s ease;
	}
	&:hover{
		img{
			transform: scale(1.1);
		}
	}
}
.tnx{
	padding-top: 22vh;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	justify-content: center;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	@include media($def){
		padding-top: 12vh;
	}
	&__title{
		font-size: rem(48px);
		color: $c-orange;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-align: center;
		margin-bottom: rem(36px);
	}
	&__text{
		font-size: rem(30px);
		line-height: rem(36px);
		color: $c-white;
		text-align: center;
		margin-bottom: rem(60);
		b{
			display: block;
		}
	}
	.custom-mfp-close{
		position: relative;
		top:0;
		bottom:auto;
		right:auto;
		left: 0;
		margin: 0 auto;
	}
}
.mfp-bg{
	height: 200%!important;
	bottom: 0;
	margin: auto;
}
.slick-track{
	margin: 0 auto;
}
.mfp-content{
	margin-top: 40px;
}
.zoom-in.mfp-ready.mfp-bg{
	opacity: 0!important;
}
.mfp-container{
	height: auto!important;
	&:after{
		position: absolute;
		height: 100%;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #000;
		opacity: 0.8;
		content: "";
	}
}