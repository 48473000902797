////
/// @group _partials/breadcrumbs.scss
////

// scss
// ================================

.breadcrumbs {
	width: 100%;
	display: flex;
	line-height: 0;
	margin: 0 auto;
	font-size: 14px;
	max-width: 1450px;
	align-items: center;
	text-transform: uppercase;
	height: 160px;
	flex-wrap: wrap;
	    align-content: center;
}
.breadcrumbs-wrapper{
	width: 100%;
	padding: 0 14px;
	background-repeat: no-repeat;
	background-position: right;
	background-size: cover;
	margin-bottom: 50px;
    background-color: #0a202d;
}
@media (max-width: 1023px){
	.breadcrumbs-wrapper{
    	background-image: none!important;
    }
}
.breadcrumbs a {
	color: $c-orange;
	text-decoration: underline;
	font-family: 'Bebas Neue';
	font-weight: 300;
	letter-spacing: 0.4em;
	text-decoration: none;
	position: relative;
	&:after{
		position: absolute;
		left: -2px;
		bottom: -4px;
		width: 100%;
		height: 1px;
		background-color: $c-orange;
		content: "";
	}
}

.breadcrumbs span {
	color: $c-white;
	display: inline-flex;
	vertical-align: top;
	font-size: 1em;
	line-height: 1.4;
	margin-right: .5em;
	font-family: 'Bebas Neue';
	font-weight: 300;
	letter-spacing: 0.4em;
	margin: 5px 0;
	white-space: nowrap;

	&:before {
		margin-right: .5em;
		content: '/';
		display: inline-block;
		vertical-align: top;
	}

	&:first-child:before {
		display: none;
	}
}

.breadcrumbs {
	@include media( $md, max ) {
		display: flex;

		span {
			&, &:before {
				display: none;
			}

			&:nth-last-child(2) {
				display: block;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 100%;
				background: none;
				margin: 0;

				&:before {
					content: '<';
					margin: 0 5px 0 3px;
					display: inline-block;
				}
			}
		}
	}
}
