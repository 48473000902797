.objects{
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-bottom: 0;
	margin-bottom: 48px;
	background-repeat: no-repeat;
	background-position: 50%;
    background-size: contain;
	@include media($xl){
		padding-bottom: 100px;
	}
	&__title{
		margin-right: 2vw;
		text-align: center;
	}
}
@media (max-width: 767px){
	.objects{
		background-image: none!important;
	}
}
.objects-slider{
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	.slick-list{
		padding: 10px 10px;
	}
}
.objects-slider-wrapper{
	position: relative;
	max-width: 1460px;
	margin: 0 auto 20px auto;
	width: 100%;
	@include media($def){
		margin: 0 auto 50px auto;
	}
}
.slick-slide{
	outline: none;
}
.objects-slider-item{
	width: 460px;
	height: 350px;
	position: relative;
	text-decoration: none;
	display: block;
	margin: 10px;
	display: none;
	@include media($md){
		height: 300px;
	}
	@include media($mg){
		width: 460px;
		height: 350px;
	}
	&__wrapper{
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
	}
	&__image{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: 50%;
		transition: transform 1s ease;
		background-size: cover;
		z-index: 4;
		&:after{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			content: "";
			background-color: #14384b;
			opacity: 0.5;
		}
	}
	&__descr{
		z-index: 5;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		color: $c-white;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		flex-direction: column;
		transition: color 0.5s ease;
	}
	&__name{
		text-align: center;
	}
	&__name,
	&__date{
		font-size: 22px;
		letter-spacing: 0.1em;
		@include media($md){
			font-size: 26px;
		}
		@include media($mg){
			font-size: 30px;
		}
	}
	&__date{
		font-weight: 300;
	}
	&:after{
		box-shadow: 0px 8px 34px -3px rgba(0, 0, 0, 0.75);
		position: absolute;
		content: "";
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 1s ease;
	}
	&:hover{
		.objects-slider-item__descr{
			color: $c-orange;
		}
		.objects-slider-item__image{
			transform: scale(1.1);
		}
		&:after{
			opacity: 1;
		}
	}
}
