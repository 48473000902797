
.slider-arrow{
	width: 90px;
	height: 90px;
	background-color: $c-lblue;
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	cursor: pointer;
	transition: background-color 0.5s ease;

	&--left{
		left: 0;
		display: none;
		@include media($psh){
			display: block;
		}
	}
	&--right{
		right: 0;
		display: none;
		@include media($psh){
			display: block;
		}
	}
	&:before{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $c-orange;
		transform: scaleY(0);
		content: "";
		transition: transform 0.5s ease;
		transform-origin: bottom;
	}
	&:hover{
		&:before{
			transform: scaleY(1);
		}
		.arrow-image-wrapper__image-part-1{
			border-color: $c-blue;
			&:after{
				background-color: $c-blue;
			}
		}
		.arrow-image-wrapper__image-part-2{
			background-color: #d4af1d;
			&:after{
				border-color: #d4af1d;
			}
		}
	}
	&:active{
		.arrow-image-wrapper--rotate-left{
			transform: rotate(-90deg) scale(0.8);
			transition: transform 0s ease;
		}
		.arrow-image-wrapper--rotate-right{
			transform: rotate(90deg) scale(0.8);
			transition: transform 0s ease;
		}
		.arrow-image-wrapper__image-part-2{
			transform: scale(0.6);
			transition: transform 0s ease;
		}
	}
}
.arrow-image-wrapper{
	position: relative;
	z-index: 5;

	&--rotate-right{
		transform: rotate(90deg);
		transition: transform 0.5s ease;
	}
	&--rotate-left{
		transform: rotate(-90deg);
		transition: transform 0.5s ease;
	}
	&--rotate-right,
	&--rotate-left{
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
		height: 100%;

	}
	&__image-part-1{
        width: 26px;
        height: 26px;
        border-right: 1px solid $c-white;
        border-top: 1px solid $c-white;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        transform: rotate(-45deg);
        transition: border-color 0.5s ease;
        z-index: 5;
        &:after{
            position: absolute;
            right: -1px;
            top: 0;
            content: "";
            width: 1px;
            height: 38px;
            background-color: $c-white;
            transform-origin: top;
            transform: rotate(45deg);
            transition: background-color 0.5s ease;
        }
    }
    &__image-part-2{
        width: 12px;
        height: 12px;
        position: absolute;
        left: 1px;
    	top: 22px;
        right: 0;
        margin: 0 auto;
        content: "";
        background-color: #cacede;
        border-radius: 100%;
        z-index: 2;
        transition: background-color 0.5s ease, transform 0.5s ease;
        &:after{
            width: 42px;
            height: 42px;
            position: absolute;
            border-right: 1px solid #cacede;
            border-top: 1px solid #cacede;
            transform: rotate(-45deg);
            top: 13px;
            left: -15px;
            right: 0;
            margin: 0 auto;
            content: "";
            transition: border-color 0.5s ease;
        }
    }
}
.slider-arrow-group{
    width: 182px;
    position: absolute;
    bottom: 22px;
    right: 28px;
    height: 90px;
    z-index: 5;
}
.partners-slider-item{
	
	img{
		margin: 0 auto;
	}
}