.partners-slider{
	max-width: 1156px;
	margin: 0 auto;
	width: 100%;
}
.partners-slider-wrapper{
	position: relative;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	@include media($def){
		height: 90px;
	}
	@include media($psh){
		padding: 0 90px;
	}
}
.partners-slider-item{
	margin: 0;
	display: none;
}
.partners{
	margin-bottom: 20px;
	@include media($def){
		margin-bottom: 46px;
	}
}