.main-nav{
	list-style-type: none;
	display: none;
	height: 70px;
	align-items: center;
	padding: 0;
	margin: 0;
	z-index: 25;
	position: relative;
	transition: opacity 0.3s 0.5s ease;
	@include media($def){
		display: flex;
	}
	@include media($mg){
		height: 90px;
	}

	&__item{
		height: 100%;
	}
	&--exp{
		border-top: 1px solid #d6d6d6;
	}
	&--footer{
		max-width: 100%;
		transform: translateX(-20px);
		margin: 0 auto;
		justify-content: center;
		padding-right: 34px;
		flex-wrap: wrap;
		height: auto;
		min-height: 90px;
		.main-nav__link{
			padding: 0 16px;
			letter-spacing: 0.06em;
		}
		.main-nav__item{
			height: auto;
		}
	}
	&__link{
		padding: 0 10px;
		height: 100%;
		display: inline-flex;
		align-items: center;
		color: $c-lblue;
		text-decoration: none;
		text-transform: uppercase;
		transition: color 0.5s ease;
		letter-spacing: 0.1em;
	    white-space: nowrap;
	    font-size: 18px;
	    @include media($lg){
			padding: 0 12px;
			font-size: 20px;
	    }
		@include media($mg){
			padding: 0 22px;
			font-size: 24px;
		}
		

		span{
			position: relative;
			display: inline-block;
			padding-bottom: 2px;
			&:after{
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				height: 2px;
				width: 100%;
				background-color: $c-blue;
				transform-origin: left;
				transform: rotateY(90deg);
				transition: transform 0.5s ease;
			}
		}
		&:hover{
			color: $c-blue;
			span{
				&:after{
					transform: rotateY(0deg);
				}
			}
		}
		&.is-active{
			pointer-events: none;
			color: $c-blue;
			span{
				&:after{
					transform: rotateY(0deg);
				}
			}
		}
	}
	&.is-disabled{
		opacity: 0;
		transition: opacity 0.3s ease;
	}
}
@media (max-width: 1880px){
	.main-nav--footer{
		padding-bottom: 20px;
	}
}