////
/// @group _partials/view-size.scss
////


// scss
// ================================

.view-size {
	position: relative;
	max-width: 1420px;
	max-width: calc( 1420px + 32px );
	padding: 0 16px;
	margin: 0 auto;
	@include clearfix;
}

// отображение
.view-size {
	&--no-gap {
		max-width: $lg;
	}
}
