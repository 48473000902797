.aside{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 200px;
	// width: 188px;
	background-color: $c-grey;
	z-index: 25;
	padding-left: 10px;
	padding-top: 110px;
	display: none;
	@include media($def){
		display: block;
	}
	@include media($mg){
		width: 280px;
	}
	&__404{
		justify-content: flex-end;
		align-items: flex-end;
		padding-left: 6px;
		padding-bottom: 30px;
		display: none;
		@include media($def){
			display: flex;
		}
		.sub-nav__item{
			display: none;
		}
	}
}