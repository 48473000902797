.contacts-phones{
	display: flex;
	flex-direction: column;
	padding: 0 6px;
	&__item{
		
	}
	&__link{
		text-decoration: none;
		letter-spacing: 0.1em;
		&--sub-contacts{
			color: $c-white;
		}
		&--main{
			color: $c-blue;
		}
	}
}
.contacts-addres{
	line-height: 1.2em;
	&--sub-contacts{
		color: $c-white;
	}
	&--main{
		color: $c-blue;
		max-width: 290px;
	}
}
.comtacts-mail{
	&__item{
		&--sub-contacts{
			color: $c-white;
		}
		&--main{
			color: $c-blue;
		}
	}
}
.contacts-soc{
	&__title{
		&--sub-contacts{
			color: $c-white;
			margin-bottom: 14px;
			max-width: 290px;
		}
		&--main{
			color: $c-lblue;
			margin-bottom: 24px;
		}
	}
}
.contacts-main-block{
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	flex-wrap: wrap;
	@include media($r1023){
		justify-content: space-between;
		margin-bottom: 70px;
	}
	&__title{
		font-weight: bold;
		font-family: 'MagistralC';
		font-size: 20px;
		line-height: 100%;
		margin-bottom: 14px;
		color: $c-lblue;
		@include media($def){
			font-size: 24px;
			margin-bottom: 24px;
		}
	}
	.contacts-soc{
		width: 100%;
		max-width: 100%;
		@include media($psh){
			width: 50%;
			max-width: 100%;
		}
		@include media($r1023){
			width: auto;
			max-width: 270px;
		}
		
	}
	.soc-block{
		max-width: 270px;
		justify-content: flex-start;
		@include media($r1023){
			justify-content: center;
		}
	}
}
.contacts-sblock{
	.contacts-phones{
		padding: 0;
	}
	padding-right: 10px;
	width: 100%;
	margin-bottom: 20px;
	max-width: 100%;
	@include media($r1023){
		max-width: 290px;
		width: 20%;
		margin-bottom: 0;
	}
	@include media($psh){
		width: 50%;
		max-width: 100%;
	}
	
}
.contacts-page{
	
}