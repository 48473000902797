.sub-contacts{
	height: 50px;
	position: relative;
	z-index: 25;
	&__content{
		background-color: $c-blue;
		width: 100%;
		padding: 16px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		align-content: center;
		box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
		@include media($xl){
			padding: 30px;
		}
	}
	&__group{
		width: 250px;
		padding: 0 6px;
	}
}
@media (max-width: 1250px){
	.sub-contacts .title-t1{
		font-size: 34px;
	}
}

@media (max-width: 1000px){
	.sub-contacts{
		display: none;
	}
}
