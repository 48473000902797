.text-1{
	font-family: 'Bebas Neue';
}
.text-2{
	font-family: 'MagistralC';
}
.text-3{
	font-family: 'Proxima Nova Rg';
}
.title-t1{
	font-size: 36px;
	color: $c-white;
	font-weight: 300;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	@include media($sm){
		font-size: 40px;
	}
	@include media($xl){
		font-size: 48px;
	}
}
.title-t2{
	font-size: 26px;
	color: $c-white;
	font-weight: 300;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	@include media($mg){
		font-size: 30px;
	}
}
.title-t3{
	color: $c-lblue;
	font-weight: 300;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	font-size: 30px;
	@include media($def){
		font-size: 44px;
	}
	@include media($mg){
		font-size: 48px;
	}
}
.content-title{
	margin-bottom: 18px;
	@include media($xl){
		margin-bottom: 40px;
	}
}
.page-title{
	text-transform: uppercase;
	font-size: 28px;
	line-height: 100%;
	color: $c-lblue;
	font-family: 'Bebas Neue';
	margin-top: 0;
	margin-bottom: 20px;
	font-weight: bold;
	letter-spacing: 0.1em;
	@include media($md){
		font-size: 38px;
	}
	b{
		display: block;
	}
	@include media($def){
		font-size: 48px;
		margin-bottom: 40px;
	}
	&--news{
		font-weight: 300;
	}
}
.content-title--t2{
	font-size: 36px;
	font-family: 'Bebas Neue';
	font-weight: bold;
	color: $c-lblue;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	margin-bottom: 42px;
}
.single-text{
	font-size: 20px;
	font-weight: 300;
	line-height: 24px;
	margin-bottom: 30px;
	@include media($xl){
		font-size: 24px;
	}
}
@media( max-width: 1500px){
	.wus-item__title{
		font-size: 22px;
	}
}