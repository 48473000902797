////
/// @group _partials/view-header.scss
////


// scss
// ================================

.view-header {
	position: fixed;
	width: 100%;
	padding: 6px;
	top: 0;
	left: 0;
	display: flex;
	z-index: 56;
	background-color: $c-white;
	height: 64px;
	@include media($def){
		height: auto;
		padding: 10px 1.4vw  10px 378px;
	}
	@include media($mg){
		padding: 10px 1.8vw 10px 504px;
	}
	&__logo{
		width: 190px;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		bottom: 0;
		top: 0;
		padding-left: 0;
		transition: opacity 0.3s 0.5s ease;
		@include media($sm){
			width: 274px;
		}
		@include media($def){
			padding-left: 20px;
			width: 366px;
			right: auto;
			bottom: auto;
		}
		@include media($xl){
			width: 383px;
		}
		@include media($mg){
			width: 504px;
			padding-left: 54px;
		}
		&.is-disabled{
			opacity: 0;
			transition: opacity 0.3s ease;
			@include media($def){
				opacity: 1;
			}
		}
	}
	&__phones-block,
	&__search-block{
		display: flex;
		justify-content: center;
		align-items: center;
		
	}
	&__phones-block{
		padding-bottom: 0px;
		transition: opacity 0.3s 0.5s ease;
		@include media($def){
			padding-bottom: 6px;
		}
		&.is-disabled{
			opacity: 0;
			transition: opacity 0.3s ease;
		}
	}
	&__search-block{
		padding-left: 1vw;
		position: relative;
		@include media($mg){
			padding-left: 2.4vw;
		}
	}
	&__items-group{
		display: flex;
		margin-left: auto;
	}
}

@media only screen and (min-width: 1700px) {
  .view-header {
    padding: 10px 2.4vw 10px 504px;
  }
}