.advantages{
	padding-top: 50px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 34px;
	&__image{
		z-index: -1;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 310px;
		background-size: cover;
		background-position: 50%;
		background-repeat: no-repeat;
	}
	&__grid{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 20px;
		@include media($def){
			margin-bottom: 48px;
		}
	}
}
.advantages-item{
	width: 266px;
	height: 248px;
	position: relative;
    transform-style: preserve-3d;
    transform-origin: center;
	overflow: hidden;
    margin: 10px;
    .bg-holder__left{
    	display: block;
    }
	&__front,
	&__end{
		position: absolute;
		left: 0;
		top: 0;
		width: 	100%;
		height: 100%;
		transition: transform 0.5s ease, opacity 0.3s ease;
		padding: 36px 20px;
	}
	&__front{
		background-color: $c-lblue;
		width: 	100%;
		z-index: 1;
	}
	&__end{
		background-color: $c-orange;
		opacity: 1;
		transform: rotateX(-90deg);
		transform-origin: bottom;
		z-index: 2;
		&:after{
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			opacity: 0;
			box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
			transition: opacity 0.3s 0s ease;
		}
	}
	&__title{
		text-align: center;
		line-height: 100%;
		margin-bottom: 	30px;
		b{
			display: block;
		}
		&--end{
			opacity: 0;
			transition: opacity 0.3s 0s ease;
		}
	}
	&__text{
		text-align: center;
		font-size: 16px;
		color: $c-blue;
		line-height: 24px;
		overflow: hidden;
		height: 96px;
		opacity: 0;
			transition: opacity 0.3s 0s ease;
	}
	&__end-title-inner{
		span{
			position: relative;
			display: inline-block;
			color: $c-orange;
			line-height: 100%;
			&:before{
				position: absolute;
				left: -30px;
				top: 0;
				bottom: 0;
				content: "";
				margin: 0 auto;
				right: 0;
				width: calc(100% + 60px);
				height: 100%;
				background-color: $c-blue;
				z-index: -1;
			}
		}
	}
	&:hover{
		.advantages-item{
			&__front{
			}
			&__end{
				transform: rotateY(0);
				&:after{
					opacity: 1;
					transition: opacity 0.5s 0.1s ease;
				}
			}
		}
		.advantages-item__title--end,
		.advantages-item__text{
			opacity: 1;
			transition: opacity 0.5s ease;
		}
	}
}
@media (max-width: 1722px){
	.advantages-item__end{
		padding: 14px 8px;
	}
	.advantages-item{
		margin: 6px;
		width: 220px;
    	height: 218px;
	}
	.advantages-item__title{
		font-size: 24px;
	}
	.advantages-item__text{
		line-height: 18px;
	}
}