////
/// @group _partials/view-wrapper.scss
////


// scss
// ================================

.view-wrapper {
	position: relative;
	min-height: 100%;
	overflow: hidden;
	display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
}