////
/// @group _functions/rem.scss
////

/// Получение размера в `rem` от основного значения
/// @param {Number} $pxval - размер в `px`
/// @param {Number} $base [12px] - основное значение, которое берется за единицу
/// @return {Number}
@function rem($pxval, $base: 12px) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1rem;
}
