._fwb{
	font-weight: bold;
}
._fz18{
	font-size: 18px!important;
}
._fz24{
	font-size: 20px;
	@include media($xl){
		font-size: 24px;
	}
}
._fz48{
	font-size: 48px;
}
._center{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
._tac{
	text-align: center;
}
._c-white{
	color: $c-white;
}
._columns-2{
	columns: 1;
	p{
		max-width: 100%!important;
	}
} 
._mgb30{
	margin-bottom: rem(30);
}
._w100{
	width: 100%;
}
._w66{
	width: 100%;
	flex-wrap: wrap;
	display: flex;
	@include media($lg){
		width: calc(66.666% - 10px);
	}
}
._w33{
	width: 100%;
	@include media($lg){
		width: calc(33.333% - 10px);
	}
	&--2{
		width: 100%;
		@include media($lg){
			width: calc(33.333% - 13.33px);
		}
	}
}
._w50{
	width: 100%;
	@include media($lg){
		width: calc(50% - 10px);
	}
}
._w-mgr{
	margin-right: 0;
	@include media($lg){
		margin-right: 20px;
	}
}
._mga{
	margin: 0;
	@include media($md){
		margin: 0 auto;
	}
}
._pr160{
	padding-right: 0;
	@include media($md){
		padding-right: 160px;
	}
}