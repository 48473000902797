.wezom{
	text-decoration: none;
	display: flex;
	align-items: center;
	flex-direction: column;
	@include media($def){
		flex-direction: row;
	}
	&__text{
		font-family: 'Proxima Nova Rg';
		font-size: 12px;
		color: $c-lblue;
		margin-right: 0;
		margin-top: 10px;
		order: 2;
		transition: color 0.5s ease;
		@include media($def){
			margin-top: 0;
			order: 1;
			margin-right: 10px;
		}
	}
	&__logo{
		width: 44px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		background-color: #d01400;
		text-decoration: none;
		transition: border-radius 0.5s ease;
		order: 1;
		@include media($def){
			order: 2;
		}
		&:hover{
			border-radius: 100%;
			.wezom__logo-holder{
				transform: scale(0.8);
			}
		}
		svg{
			fill: $c-white;
			width: 34px;
			height: 12px;
		}
	}
	&__logo-holder{
		transition: transform 0.8s ease;
		transform: scale(1);
	}
	&:hover{
		.wezom__text{
			color: #d01400;
		}
	}
}