.to-top {
    position: fixed;
    bottom: 60px;
    left: auto;
    right: 20px;
    height: 46px;
    width: 46px;
    z-index: 255;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s cubic-bezier(1,.15,1,.01), opacity 0.3s ease;
    display: flex;
    transform: translateY(100%);
    transform-origin: center;
    backface-visibility: hidden;
    opacity: 0;
    @include media($def){
        left: 10px;
        right: auto;
        bottom: 20px;
        height: 120px;
        width: 180px;
    }
    @include media($mg){
        left: 50px;
        // left: 4px;
    }

    
    &.js-active {
        transform: translateY(0%);
        opacity: 1;
        transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
    }
    .to-top__wrapper{
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        transform-origin: center;
        flex-direction: column;
        width: 46px;
        border-radius: 100%;
        height: 46px;
        background-color: $c-orange;
        @include media($def){
            width: auto;
            height: auto;
            border-radius: 0;
            background-color: transparent;
        }
    }
    &__image{
        margin-bottom: 0;
        width: 46px;
        height: 46px;
        position: relative;
        @include media($def){
            width: 66px;
            height: 50px;
            margin-bottom: 30px;
        }
    }
    &__text{
        text-transform: uppercase;
        font-size: 18px;
        color: #899297;
        letter-spacing: 0.05em;
        font-family: 'Bebas Neue';
        transition: color 0.5s ease;
        display: none;
        @include media($def){
            display: block;
        }
    }
    &__image-part-1{
        width: 16px;
        height: 16px;
        border-right: 2px solid $c-blue;
        border-top: 2px solid $c-blue;
        position: absolute;
        top: 6px;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        transform: rotate(-45deg);
        z-index: 5;
        @include media($def){
            top: 0;
            width: 26px;
            height: 26px;
            border-right: 1px solid $c-blue;
            border-top: 1px solid $c-blue;
        }
        &:after{
            position: absolute;
            right: -1px;
            top: 0;
            content: "";
            width: 1px;
            display: none;
            height: 38px;
            background-color: $c-blue;
            transform-origin: top;
            transform: rotate(45deg);
            @include media($def){
                display: block;
            }
        }
    }
    &__image-part-2{
        width: 12px;
        height: 12px;
        position: absolute;
        left: -1px;
        top: 2px;
        right: 0;
        margin: 0 auto;
        content: "";
        background-color: #cacede;
        border-radius: 100%;
        z-index: 2;
        display: none;
        transition: transform 0.5s ease;
        @include media($def){
            display: block;
        }
        &:after{
            width: 42px;
            height: 42px;
            position: absolute;
            border-right: 1px solid #cacede;
            border-top: 1px solid #cacede;
            transform: rotate(-45deg);
            top: 13px;
            left: -15px;
            right: 0;
            margin: 0 auto;
            content: "";
        }
    }
    &:hover{
        .to-top__text{
            color: $c-blue;
        }
        .to-top__image-part-2{
            transform: scale(0);
        }
    }
}