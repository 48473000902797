.soc-block{
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	&__item{
		margin-right: 6px;
		margin-bottom: 6px;
		@include media($mg){
    		margin-right: 10px;
			margin-bottom: 10px;
		}
	}
	&__link{
		border: 1px solid $c-lblue;
		display: block;
		width: 30px;
    	height: 38px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		@include media($mg){
    		width: 44px;
			height: 50px;
		}

		svg{
			max-height: 16px;
			max-width: 18px;
			fill: $c-lblue;
			position: relative;
			z-index: 1;
			transition: fill 0.3s ease;
		}
		&:after{
			position: absolute;
			content: "";
			bottom: -2px;
			left: -1px;
			width: calc(100% + 2px);
			height: 2px;
			background-color: $c-teal;
			z-index: 0;
			transition: background-color 0.3s ease, bottom 0.5s ease, height 0.5s ease;
		}
		&--fb{
			&:hover{
				&:after{
					background-color: #3b5998;
				}
			}
		}
		&--vk{
			&:hover{
				&:after{
					background-color: #2f79b2;
				}
			}
		}
		&--inst{
			&:hover{
				&:after{
					background-color: #c42b66;
				}
			}
		}
		&--gp{
			&:hover{
				&:after{
					background-color: #e53f30;
				}
			}
		}
		&--ok{
			&:hover{
				&:after{
					background-color: #ff9600;
				}
			}
		}
		&--sub-contacts{
			border-color: #3e6478;
			svg{
				fill: $c-white;
			}
			&:after{
				background-color: #3e6478;
			}
		}
		&:hover{
			svg{
				fill: $c-white;
			}
			&:after{
				height: calc(100% + 2px);
				bottom: -1px;
			}
		}
	}
}