////
/// @group _partials/pagination.scss
////

// scss
// ================================

.pagination {
	text-align: center;
	display: flex;
	justify-content: center;
	position: relative;
	padding-bottom: 24px;
	border-bottom: 1px solid #d6d6d6;
	&__btn{
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		font-weight: bold;
		color: #6a73aa;
		text-decoration: none;
		font-size: 18px;
		font-family: 'MagistralC';
		transition: background-color 0.5s ease, color 0.5s ease;
		@include media($psh){
			width: 90px;
			height: 90px;
			font-size: 24px;
		}
		&.is-active{
			background-color: $c-orange;
			color: #2b3c90;
			pointer-events: none;
		}
		&:hover{
			background-color: $c-orange;
			color: #2b3c90;
		}
	}
}
