////
/// @group _partials/view-section.scss
////


// scss
// ================================

.view-section {
	position: relative;
	padding-top: 64px;
	padding-left: 0;
	@include media($def){
		// padding-left: 188px;
		padding-left: 200px;
	}
	@include media($lg){
		padding-top: 93px;
	}
	@include media($mg){
		padding-left: 280px;
		padding-top: 110px;
	}
}
