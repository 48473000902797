.logo-block{
	fill: $c-blue;
	display: flex;
	justify-content: space-between;
	align-content: center;
	align-items: center;
	height: 100%;
	padding-top: 3px;
}
.logo{
	&--head{
		svg{
			max-width: 84px;
    		max-height: 42px;
			@include media($sm){
				max-width: 110px;
				max-height: 60px;
			}
			@include media($def){
			    max-width: 146px;
    			max-height: 72px;
			}
			@include media($mg){
				max-width: 174px;
				max-height: 84px;
			}
		}
	}
	&--footer{
		margin-bottom: 10px;
		@include media($def){
			margin-bottom: 0;
		}
		svg{
			max-width: 92px;
			max-height: 44px;
		}
	}
}
.slogan-text{
	margin-left: 4px;
	display: block;
	@include media($sm){
		margin-left: 10px;
	}
	@include media($def){
		margin-left: 0;
	}
	svg{
	    max-width: 114px;
   		max-height: 52px;
    	@include media($sm){
			max-width: 166px;
    		max-height: 68px;
    	}
    	@include media($xl){
    		max-width: 184px;
    		max-height: 74px;
		}
		@include media($mg){
			max-width: 226px;
			max-height: 90px;
		}
	}
}