@media only screen and (min-width: 320px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 375px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 500px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 480px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 732px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 769px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1023px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1160px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1224px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1366px) {
  /*! $mqpacker hack */
}

@media only screen and (min-width: 1620px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1619px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1365px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1223px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1159px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 1022px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 768px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 731px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 767px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 479px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 499px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 374px) {
  /*! $mqpacker hack */
}

@media only screen and (max-width: 319px) {
  /*! $mqpacker hack */
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

figcaption,
figure {
  display: block;
}

figure {
  margin: 1em 0;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: inherit;
}

b, strong {
  font-weight: bold;
}

code, kbd, samp {
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline-width: 0;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

abbr[title], dfn[title] {
  cursor: help;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[readonly] {
  cursor: default;
}

input:disabled {
  cursor: not-allowed;
}

html {
  position: relative;
  height: 100%;
  font-size: 12px;
}

@media only screen and (min-width: 769px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1224px) {
  html {
    font-size: 16px;
  }
}

*, :after, :before {
  box-sizing: inherit;
}

a {
  color: #43f;
  transition: color 0.5s ease;
}

a:hover {
  color: #fecc00;
}

body {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-width: 320px;
  background-color: #fff;
}

pre {
  padding: 1.2em;
  tab-size: 4;
  border-width: 0;
  white-space: pre;
  white-space: pre-wrap;
  background: #eee;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  height: auto;
}

small {
  display: inline-block;
  vertical-align: text-bottom;
}

svg {
  pointer-events: none;
}

hr {
  border-width: 0;
  border-top: 1px solid #aaa;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-moz-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

::-ms-selection {
  text-shadow: none;
  color: #fff;
  background: #2597ff;
}

input {
  box-shadow: none;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
  opacity: .5;
}

input:-ms-input-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea {
  box-shadow: none;
}

textarea::-webkit-input-placeholder {
  color: #999;
}

textarea::-moz-placeholder {
  color: #999;
  opacity: .5;
}

textarea:-ms-input-placeholder {
  color: #999;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.view-wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.view-size {
  position: relative;
  max-width: 1420px;
  max-width: calc( 1420px + 32px);
  padding: 0 16px;
  margin: 0 auto;
}

.view-size:after {
  content: '';
  clear: both;
  display: block;
  height: 0;
}

.view-size--no-gap {
  max-width: 1224px;
}

.view-section {
  position: relative;
  padding-top: 64px;
  padding-left: 0;
}

@media only screen and (min-width: 1160px) {
  .view-section {
    padding-left: 200px;
  }
}

@media only screen and (min-width: 1224px) {
  .view-section {
    padding-top: 93px;
  }
}

@media only screen and (min-width: 1620px) {
  .view-section {
    padding-left: 280px;
    padding-top: 110px;
  }
}

.view-header {
  position: fixed;
  width: 100%;
  padding: 6px;
  top: 0;
  left: 0;
  display: flex;
  z-index: 56;
  background-color: #fff;
  height: 64px;
}

@media only screen and (min-width: 1160px) {
  .view-header {
    height: auto;
    padding: 10px 1.4vw  10px 378px;
  }
}

@media only screen and (min-width: 1620px) {
  .view-header {
    padding: 10px 1.8vw 10px 504px;
  }
}

.view-header__logo {
  width: 190px;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
  padding-left: 0;
  transition: opacity 0.3s 0.5s ease;
}

@media only screen and (min-width: 480px) {
  .view-header__logo {
    width: 274px;
  }
}

@media only screen and (min-width: 1160px) {
  .view-header__logo {
    padding-left: 20px;
    width: 366px;
    right: auto;
    bottom: auto;
  }
}

@media only screen and (min-width: 1366px) {
  .view-header__logo {
    width: 383px;
  }
}

@media only screen and (min-width: 1620px) {
  .view-header__logo {
    width: 504px;
    padding-left: 54px;
  }
}

.view-header__logo.is-disabled {
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media only screen and (min-width: 1160px) {
  .view-header__logo.is-disabled {
    opacity: 1;
  }
}

.view-header__phones-block, .view-header__search-block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-header__phones-block {
  padding-bottom: 0px;
  transition: opacity 0.3s 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .view-header__phones-block {
    padding-bottom: 6px;
  }
}

.view-header__phones-block.is-disabled {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.view-header__search-block {
  padding-left: 1vw;
  position: relative;
}

@media only screen and (min-width: 1620px) {
  .view-header__search-block {
    padding-left: 2.4vw;
  }
}

.view-header__items-group {
  display: flex;
  margin-left: auto;
}

@media only screen and (min-width: 1700px) {
  .view-header {
    padding: 10px 2.4vw 10px 504px;
  }
}

.view-footer {
  position: relative;
  margin-left: 0;
  padding-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .view-footer {
    height: auto;
    margin-left: 200px;
  }
}

@media only screen and (min-width: 1620px) {
  .view-footer {
    margin-left: 280px;
  }
}

.view-footer__bottom {
  max-width: 1480px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  flex-direction: column;
  position: relative;
  z-index: 25;
}

@media only screen and (min-width: 1160px) {
  .view-footer__bottom {
    padding-top: 16px;
    padding-right: 34px;
    flex-direction: row;
    justify-content: space-between;
  }
}

.view-footer__bottom .logo-block {
  height: 92px;
}

.view-footer__404 {
  position: relative;
  bottom: 0;
  width: 100%;
  left: 0;
}

@media only screen and (min-width: 1023px) {
  .view-footer__404 {
    position: fixed;
  }
}

@media only screen and (min-width: 1160px) {
  .view-footer__404 {
    width: calc(100% - 200px);
  }
}

@media only screen and (min-width: 1224px) {
  .view-footer__404 {
    width: calc(100% - 280px);
  }
}

.view-footer__404 .main-nav__item {
  display: none;
}

.view-footer__group {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .view-footer__group {
    margin-bottom: 0;
    flex-direction: row;
  }
}

.copy {
  padding-left: 8px;
  color: #899297;
  font-size: 12px;
  font-family: 'Proxima Nova Rg';
}

@media (min-width: 1700) {
  .view-footer__bottom {
    padding-top: 44px;
  }
}

.zoom-in {
  /* animate in */
  /* animate out */
  /* Dark overlay, start state */
  /* animate in */
  /* animate out */
}

.zoom-in .hidden-wrapper {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0.8);
}

.zoom-in.mfp-ready .hidden-wrapper {
  opacity: 1;
  transform: scale(1);
}

.zoom-in.mfp-removing .hidden-wrapper {
  transform: scale(0.8);
  opacity: 0;
}

.zoom-in.mfp-bg {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

.hidden-wrapper {
  position: relative;
  padding: 0;
  margin: 0 auto;
  background-color: transparent;
  width: 100%;
  max-width: 920px;
}

@media only screen and (min-width: 769px) {
  .hidden-wrapper {
    width: 96%;
  }
}

.hidden-wrapper--lg {
  max-width: 1600px;
  min-height: 100vh;
  margin: 1.2rem auto;
  width: 100%;
  position: relative;
  padding: 0;
}

.hidden-wrapper--md {
  max-width: 768px;
}

.hidden-wrapper--sm {
  max-width: 480px;
}

.hidden-wrapper--strip {
  background: none;
  padding: 0;
}

.hidden-wrapper--no-gap {
  padding: 0;
}

.hidden-wrapper ._show-in--hidden-wrapper {
  display: block;
}

.view-text {
  color: inherit;
  position: relative;
  font-size: 1rem;
  line-height: 1.5;
}

.view-text--news {
  columns: 1;
}

@media only screen and (min-width: 769px) {
  .view-text--news {
    columns: 2;
  }
}

.view-text--news p {
  max-width: 100% !important;
}

.view-text blockquote > :first-child, .view-text > :first-child {
  margin-top: 0;
}

.view-text blockquote > :last-child, .view-text > :last-child {
  margin-bottom: 0;
}

.view-text {
  font-family: 'Proxima Nova Rg';
}

.view-text h1, .view-text h2, .view-text h3, .view-text h4, .view-text h5, .view-text h6 {
  font-family: 'Bebas Neue';
  font-weight: normal;
  margin: 0;
}

.view-text h1 {
  font-size: 38px;
  color: #899297;
  line-height: 110%;
  font-weight: 300;
  margin: 14px 0 28px;
}

@media only screen and (min-width: 1160px) {
  .view-text h1 {
    font-size: 48px;
  }
}

.view-text h2 {
  font-size: 2.25em;
}

.view-text h3 {
  font-size: 1.75em;
}

.view-text h4 {
  font-size: 1.5em;
}

.view-text h5 {
  font-size: 1.25em;
}

.view-text h6 {
  font-size: 1em;
}

.view-text p, .view-text ul, .view-text ol, .view-text hr, .view-text dl, .view-text pre, .view-text address, .view-text blockquote, .view-text table,
.view-text .table-wrapper, .view-text .media-wrapper {
  margin: 0;
}

.view-text blockquote {
  padding-left: 40px;
  font-size: 24px;
  line-height: 30px;
  color: #0b3b54;
  position: relative;
  margin: 20px 0 30px 0;
  padding-top: 10px;
  max-width: 790px;
  width: 100%;
}

.view-text blockquote:after {
  position: absolute;
  left: -14px;
  content: '"';
  top: 26px;
  font-size: 90px;
  line-height: 30px;
  color: #fecc00;
  font-style: italic;
  font-weight: bold;
}

.view-text hr {
  clear: both;
  border-width: 0;
  border-top-width: 1px;
}

.view-text p {
  margin: 14px 0 28px;
  color: #000000;
  font-size: 14pt;
  line-height: 24px;
  font-weight: 300;
}

.view-text p, .view-text ul, .view-text ol {
  max-width: 100% !important;
  width: 100%;
}

textarea {
  padding: 16px !important;
  resize: none !important;
}

.view-text ul, .view-text ol {
  padding-left: 40px;
  list-style-type: none;
  margin: 14px 0 28px;
}

.view-text ul li, .view-text ol li {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #0b3b54;
  font-weight: normal;
  width: 100%;
}

.view-text ul li:before {
  position: absolute;
  content: "";
  width: 14px;
  height: 2px;
  left: -30px;
  top: 10px;
  background-color: #fecc00;
}

.view-text ol li {
  counter-increment: step-counter;
}

.view-text ol li:before {
  position: absolute;
  content: counter(step-counter) ".";
  font-weight: bold;
  color: #fecc00;
  font-size: 16px;
  top: 0;
  left: -30px;
}

.view-text dd {
  margin-left: 1.5em;
}

.view-text dt {
  font-weight: bold;
}

.view-text dd + dt {
  margin: 1em 0 0;
}

.view-text {
  width: 100%;
}

.view-text pre, .view-text code, .view-text kbd, .view-text samp {
  font-style: normal;
  font-weight: normal;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
}

.view-text pre {
  tab-size: 4;
  padding: 1em 1.5em;
  background-color: #f5f5f5;
}

.view-text code, .view-text kbd, .view-text samp {
  padding: 0 .4em;
}

.view-text code {
  background-color: #f5f5f5;
}

.view-text kbd {
  background-color: #e1f0fa;
}

.view-text samp {
  background-color: #f7f8e2;
}

.view-text iframe {
  border-width: 0;
}

.view-text audio {
  width: 100%;
}

.view-text audio[controls] {
  display: block;
}

.view-text .media-wrapper__holder {
  background-color: #f5f5f5;
  position: relative;
}

.view-text .media-wrapper__holder iframe, .view-text .media-wrapper__holder video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.view-text table {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ddd;
}

.view-text caption {
  margin-bottom: .5em;
  caption-side: top;
}

.view-text tbody, .view-text tfoot, .view-text thead, .view-text tr, .view-text th, .view-text td {
  border: inherit;
}

.view-text th, .view-text td {
  padding: .5em;
  text-align: left;
  vertical-align: top;
}

.view-text th {
  font-weight: bold;
  text-align: center;
  background-color: #f5f5f5;
}

.view-text .table-null, .view-text .table-null th, .view-text .table-null td {
  border-color: transparent;
}

.view-text .table-null th {
  background: transparent;
}

.view-text .table-zebra, .view-text .table-zebra th, .view-text .table-zebra td {
  border-color: transparent;
}

.view-text .table-zebra th {
  background: transparent;
}

.view-text .table-zebra tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.view-text a {
  color: #43f;
}

.view-text a:visited {
  color: #0e00ad;
}

.view-text a:hover {
  color: #f34;
}

.view-text a:active {
  color: #000;
}

.view-text b, .view-text strong {
  font-weight: bold;
}

.view-text i, .view-text cite, .view-text em, .view-text var, .view-text address, .view-text dfn, .view-text caption {
  font-style: italic;
}

.view-text abbr[title], .view-text dfn[title] {
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: help;
}

.view-text--article > p,
.view-text--article > h1,
.view-text--article > h2,
.view-text--article > h3,
.view-text--article > h4,
.view-text--article > h5,
.view-text--article > h6,
.view-text--article > hr,
.view-text--article > ul,
.view-text--article > ol,
.view-text--article > dl,
.view-text--article > audio,
.view-text--article > address {
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
}

.image-content-wrapper {
  position: relative;
  line-height: 0;
  width: 100%;
  max-width: 100% !important;
}

.image-content-wrapper img {
  position: relative;
  z-index: 2;
}

.image-content-wrapper:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: url("../images/dots2.png");
}

.table-wrapper {
  clear: both;
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
}

.table-wrapper__holder {
  max-width: 100%;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}

.table-wrapper__table {
  width: 100%;
  border-collapse: collapse;
}

.table-wrapper:before, .table-wrapper:after {
  content: '';
  width: 8px;
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity .25s linear;
}

.table-wrapper:before {
  left: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
}

.table-wrapper:after {
  right: 0;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.table-wrapper--outside-left:before {
  opacity: .3;
}

.table-wrapper--outside-right:after {
  opacity: .3;
}

.content-image {
  position: relative;
}

.content-image--width-1200-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.view-text--article .content-image--width-600-and-more {
  display: block;
  float: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media only screen and (max-width: 1619px) {
  .content-image--width-1000-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1365px) {
  .content-image--width-900-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 1223px) {
  .content-image--width-800-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 768px) {
  .content-image--width-500-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 767px) {
  .content-image--width-300-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 479px) {
  .content-image--width-200-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media only screen and (max-width: 374px) {
  .content-image--width-100-and-more {
    display: block;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.title {
  line-height: 1.2;
  font-weight: bold;
  color: #404040;
  font-size: 2rem;
  margin: 1.2em 0 1rem;
}

.title:first-child {
  margin-top: 0;
}

.title--sup {
  font-size: 2.5rem;
}

.title--sub {
  font-size: 1.5rem;
}

.breadcrumbs {
  width: 100%;
  display: flex;
  line-height: 0;
  margin: 0 auto;
  font-size: 14px;
  max-width: 1450px;
  align-items: center;
  text-transform: uppercase;
  height: 160px;
  flex-wrap: wrap;
  align-content: center;
}

.breadcrumbs-wrapper {
  width: 100%;
  padding: 0 14px;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  margin-bottom: 50px;
  background-color: #0a202d;
}

@media (max-width: 1023px) {
  .breadcrumbs-wrapper {
    background-image: none !important;
  }
}

.breadcrumbs a {
  color: #fecc00;
  text-decoration: underline;
  font-family: 'Bebas Neue';
  font-weight: 300;
  letter-spacing: 0.4em;
  text-decoration: none;
  position: relative;
}

.breadcrumbs a:after {
  position: absolute;
  left: -2px;
  bottom: -4px;
  width: 100%;
  height: 1px;
  background-color: #fecc00;
  content: "";
}

.breadcrumbs span {
  color: #fff;
  display: inline-flex;
  vertical-align: top;
  font-size: 1em;
  line-height: 1.4;
  margin-right: .5em;
  font-family: 'Bebas Neue';
  font-weight: 300;
  letter-spacing: 0.4em;
  margin: 5px 0;
  white-space: nowrap;
}

.breadcrumbs span:before {
  margin-right: .5em;
  content: '/';
  display: inline-block;
  vertical-align: top;
}

.breadcrumbs span:first-child:before {
  display: none;
}

@media only screen and (max-width: 768px) {
  .breadcrumbs {
    display: flex;
  }
  .breadcrumbs span, .breadcrumbs span:before {
    display: none;
  }
  .breadcrumbs span:nth-last-child(2) {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    background: none;
    margin: 0;
  }
  .breadcrumbs span:nth-last-child(2):before {
    content: '<';
    margin: 0 5px 0 3px;
    display: inline-block;
  }
}

.sitemap {
  max-width: 769px;
  padding: 1rem;
  background-color: #f6f7f9;
  position: relative;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

@media only screen and (min-width: 769px) {
  .sitemap {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.sitemap ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap li {
  list-style: none;
  line-height: 1.4em;
}

.sitemap a {
  padding: .2em 1em .2em .5em;
  display: block;
  color: #00e;
}

.sitemap a:visited {
  color: #0e00ad;
}

.sitemap a:hover {
  color: #f34;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap a:active {
  color: #000;
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul {
  padding: 0;
  margin: 1em 0;
  position: relative;
  overflow: hidden;
}

.sitemap > ul > li > ul {
  padding-left: 0;
}

.sitemap > ul ul {
  list-style: none;
  padding: 0 0 0 2em;
  margin: 0;
}

.sitemap > ul ul li {
  position: relative;
  margin: .1em 0;
}

.sitemap > ul ul li:hover {
  background-color: rgba(0, 0, 0, 0.035);
}

.sitemap > ul ul li a {
  position: relative;
  padding-left: 2.5em;
}

.sitemap > ul ul li a:before, .sitemap > ul ul li a:after {
  content: '';
  position: absolute;
  top: .2em;
  width: 1600px;
  height: 1.4em;
  text-align: right;
  opacity: .3;
  background: transparent repeat-x center right;
}

.sitemap > ul ul li a:before {
  left: 0;
  width: 2em;
  background-image: url(pic/sitemap-start.svg);
}

.sitemap > ul ul li a:after {
  right: 100%;
  background-image: url(pic/sitemap-middle.svg);
}

.sitemap > ul ul li:last-child > a:only-child:before {
  background-image: url(pic/sitemap-end.svg);
}

.pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 24px;
  border-bottom: 1px solid #d6d6d6;
}

.pagination__btn {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: bold;
  color: #6a73aa;
  text-decoration: none;
  font-size: 18px;
  font-family: 'MagistralC';
  transition: background-color 0.5s ease, color 0.5s ease;
}

@media only screen and (min-width: 732px) {
  .pagination__btn {
    width: 90px;
    height: 90px;
    font-size: 24px;
  }
}

.pagination__btn.is-active {
  background-color: #fecc00;
  color: #2b3c90;
  pointer-events: none;
}

.pagination__btn:hover {
  background-color: #fecc00;
  color: #2b3c90;
}

.form {
  position: relative;
  font-size: 18px;
}

.form__toggler-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.form__toggler-block {
  background-color: #7f6600;
  padding: 4px 9px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 1224px) {
  .form__toggler-block {
    padding: 16px 20px;
  }
}

.form__toggler-text {
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  font-family: 'MagistralC';
  transition: color 0.5s ease;
}

@media only screen and (min-width: 480px) {
  .form__toggler-text {
    font-size: 18px;
  }
}

@media only screen and (min-width: 769px) {
  .form__toggler-text {
    font-size: 24px;
  }
}

.form__toggler-text.is-active {
  color: #fecc00;
}

.form__toggler {
  height: 26px;
  width: 52px;
  border: 4px solid #fff;
  background-color: #fff;
  border-radius: 20px;
  cursor: pointer;
  margin: 0 12px;
  position: relative;
}

@media only screen and (min-width: 769px) {
  .form__toggler {
    margin: 0 26px;
  }
}

@media only screen and (min-width: 1224px) {
  .form__toggler {
    width: 82px;
    height: 36px;
  }
}

.form__toggler-group {
  display: flex;
  align-items: center;
}

.form__toggler-btn {
  position: absolute;
  border-radius: 100%;
  left: 0;
  top: 0;
  right: auto;
  width: 20px;
  height: 20px;
  transition: left 0.5s ease, margin 0.5s ease;
  background-color: #fecc00;
}

@media only screen and (min-width: 1224px) {
  .form__toggler-btn {
    width: 28px;
    height: 28px;
  }
}

.form__toggler-btn.is-active {
  left: 100%;
  margin-left: -28px;
}

.form__group {
  display: block;
  position: relative;
}

.form__label {
  cursor: default;
  display: inline-block;
  vertical-align: top;
  user-select: none;
  margin-bottom: .4rem;
}

.form__caption {
  font-size: 16px;
  font-weight: bold;
  font-family: 'MagistralC';
  margin-bottom: 4px;
  color: #fff;
}

.form__info {
  display: none;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  font-size: .7em;
  background-color: #000;
  color: #fff;
  padding: .1em .3em;
}

label.form__label {
  cursor: pointer;
}

.control-holder {
  position: relative;
}

.control-holder.weight-elev-container label {
  right: 0;
  min-width: 142px;
  font-size: 9px !important;
  margin: 17px -9px 0 0;
}

@media only screen and (min-width: 1224px) {
  .control-holder.weight-elev-container label {
    margin: 28px -10px 0 0;
    min-width: 189px;
    font-size: 12px !important;
  }
}

.control-holder label.has-error {
  display: inline-block;
  vertical-align: top;
  user-select: none;
  font-size: .75em;
  line-height: 1em;
  padding: .1em .5em;
  background-color: #000;
  color: #fff;
  position: absolute;
  top: 100%;
}

.control-holder--text input, .control-holder--text textarea, .control-holder--text select {
  display: block;
  width: 100%;
  border: 1px solid #fecc00;
  outline: none;
  padding: 0 10px;
  color: #0b3b54;
  font-family: 'MagistralC';
  line-height: 1.3em;
  font-size: 14px;
}

@media only screen and (min-width: 1224px) {
  .control-holder--text input, .control-holder--text textarea, .control-holder--text select {
    padding: 0 1.6666667rem;
    font-size: 18px;
  }
}

.control-holder--text textarea {
  resize: vertical;
  min-height: 7em;
  max-height: 18em;
}

.control-holder--text input, .control-holder--text select {
  height: 40px;
}

@media only screen and (min-width: 1224px) {
  .control-holder--text input, .control-holder--text select {
    height: 56px;
  }
}

.control-holder--flag label {
  user-select: none;
  margin-right: 1em;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.control-holder--flag label:only-child, .control-holder--flag label.has-error {
  cursor: default;
  margin-right: 0;
}

.control-holder--flag ins {
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  width: 1.2em;
  height: 1.2em;
  background-color: #fff;
  border: 1px solid;
}

.control-holder--flag input {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
}

.control-holder--flag input[type="radio"] ~ ins {
  border-radius: 50%;
}

.control-holder--flag input:checked ~ ins {
  background-color: #000;
  box-shadow: inset 0 0 0 3px #fff;
}

.control-holder--flag input:disabled ~ ins,
.control-holder--flag input:disabled ~ span {
  opacity: .5;
  cursor: not-allowed;
}

.control-holder--file label {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.control-holder--file input {
  position: absolute;
  outline: none;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.control-holder--file ._ellipsis {
  max-width: 200px;
  width: auto;
  display: inline-block;
  vertical-align: top;
}

#fPopUp {
  max-width: 80% !important;
  min-width: 280px !important;
}

@media only screen and (min-width: 769px) {
  #fPopUp {
    max-width: 50% !important;
    min-width: 400px !important;
  }
}

.logo-block {
  fill: #0b3b54;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  height: 100%;
  padding-top: 3px;
}

.logo--head svg {
  max-width: 84px;
  max-height: 42px;
}

@media only screen and (min-width: 480px) {
  .logo--head svg {
    max-width: 110px;
    max-height: 60px;
  }
}

@media only screen and (min-width: 1160px) {
  .logo--head svg {
    max-width: 146px;
    max-height: 72px;
  }
}

@media only screen and (min-width: 1620px) {
  .logo--head svg {
    max-width: 174px;
    max-height: 84px;
  }
}

.logo--footer {
  margin-bottom: 10px;
}

@media only screen and (min-width: 1160px) {
  .logo--footer {
    margin-bottom: 0;
  }
}

.logo--footer svg {
  max-width: 92px;
  max-height: 44px;
}

.slogan-text {
  margin-left: 4px;
  display: block;
}

@media only screen and (min-width: 480px) {
  .slogan-text {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 1160px) {
  .slogan-text {
    margin-left: 0;
  }
}

.slogan-text svg {
  max-width: 114px;
  max-height: 52px;
}

@media only screen and (min-width: 480px) {
  .slogan-text svg {
    max-width: 166px;
    max-height: 68px;
  }
}

@media only screen and (min-width: 1366px) {
  .slogan-text svg {
    max-width: 184px;
    max-height: 74px;
  }
}

@media only screen and (min-width: 1620px) {
  .slogan-text svg {
    max-width: 226px;
    max-height: 90px;
  }
}

.text-1 {
  font-family: 'Bebas Neue';
}

.text-2 {
  font-family: 'MagistralC';
}

.text-3 {
  font-family: 'Proxima Nova Rg';
}

.title-t1 {
  font-size: 36px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media only screen and (min-width: 480px) {
  .title-t1 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1366px) {
  .title-t1 {
    font-size: 48px;
  }
}

.title-t2 {
  font-size: 26px;
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media only screen and (min-width: 1620px) {
  .title-t2 {
    font-size: 30px;
  }
}

.title-t3 {
  color: #899297;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 30px;
}

@media only screen and (min-width: 1160px) {
  .title-t3 {
    font-size: 44px;
  }
}

@media only screen and (min-width: 1620px) {
  .title-t3 {
    font-size: 48px;
  }
}

.content-title {
  margin-bottom: 18px;
}

@media only screen and (min-width: 1366px) {
  .content-title {
    margin-bottom: 40px;
  }
}

.page-title {
  text-transform: uppercase;
  font-size: 28px;
  line-height: 100%;
  color: #899297;
  font-family: 'Bebas Neue';
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 769px) {
  .page-title {
    font-size: 38px;
  }
}

.page-title b {
  display: block;
}

@media only screen and (min-width: 1160px) {
  .page-title {
    font-size: 48px;
    margin-bottom: 40px;
  }
}

.page-title--news {
  font-weight: 300;
}

.content-title--t2 {
  font-size: 36px;
  font-family: 'Bebas Neue';
  font-weight: bold;
  color: #899297;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 42px;
}

.single-text {
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1366px) {
  .single-text {
    font-size: 24px;
  }
}

@media (max-width: 1500px) {
  .wus-item__title {
    font-size: 22px;
  }
}

.map-wrapper {
  height: 41.5vh;
  min-height: 260px;
  width: 100%;
  position: relative;
  z-index: 0;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1160px) {
  .map-wrapper {
    margin-bottom: 0px;
  }
}

.map {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.map-overlay {
  display: none;
  width: 100%;
  position: absolute;
  z-index: 25;
  height: 100%;
  background-color: transparent;
  cursor: pointer;
}

.map-overlay.is-active {
  display: block;
}

.wus-block {
  position: relative;
  margin-bottom: 70px;
}

.wus-block__grid {
  display: flex;
  margin-right: 44px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 44px;
}

@media only screen and (min-width: 1620px) {
  .wus-block__grid {
    margin-right: 2vw;
    margin-left: 0;
  }
}

.wus-block__view-size {
  max-width: 1550px;
  margin: 0 auto;
  width: 100%;
}

.wus-item {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #fecc00;
  border-left: 1px solid #fecc00;
  padding: 0 12px;
  width: 33.33%;
  margin-bottom: 0;
}

@media only screen and (min-width: 1366px) {
  .wus-item {
    width: 20%;
    border-right: 1px solid #fecc00;
    border-left: none;
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 1620px) {
  .wus-item {
    padding: 0 28px;
  }
}

.wus-item__image {
  height: 132px;
}

.wus-item__image svg {
  fill: #fecc00;
}

.wus-item__title {
  display: block;
  color: #0b3b54;
  line-height: 100%;
  margin-bottom: 20px;
}

.wus-item__title b {
  display: block;
}

.wus-item__text {
  color: #7d7d7d;
  font-size: 16px;
  line-height: 24px;
}

.wus-item:nth-child(1) .wus-item__image-wrapper {
  transform: translateY(-10px);
}

.wus-item:nth-child(1) svg {
  width: 96px;
  height: 104px;
}

.wus-item:nth-child(2) .wus-item__image-wrapper {
  transform: translateY(-18px);
}

.wus-item:nth-child(2) svg {
  width: 132px;
  height: 112px;
}

.wus-item:nth-child(3) svg {
  width: 90px;
  height: 90px;
}

.wus-item:nth-child(4) svg {
  width: 90px;
  height: 90px;
}

.wus-item:nth-child(5) svg {
  width: 94px;
  height: 90px;
}

.wus-item:first-of-type {
  border-left: 1px solid #fecc00;
}

@media (max-width: 1023px) {
  .wus-item {
    width: 300px;
  }
  .wus-block__grid {
    justify-content: center;
  }
}

.news-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.news-item {
  position: relative;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  flex-direction: column;
}

@media only screen and (min-width: 732px) {
  .news-item {
    height: 248px;
    flex-direction: row;
  }
}

.news-item--more-exp .news-item__title {
  font-size: 16px;
}

.news-item--more-exp .news-item__title b {
  max-height: 18px;
  overflow: hidden;
}

@media only screen and (min-width: 1160px) {
  .news-item--more-exp .news-item__title {
    font-size: 20px;
  }
  .news-item--more-exp .news-item__title b {
    max-height: 22px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1366px) {
  .news-item--more-exp .news-item__title {
    font-size: 22px;
  }
  .news-item--more-exp .news-item__title b {
    max-height: 24px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1620px) {
  .news-item--more-exp .news-item__title {
    font-size: 24px;
  }
  .news-item--more-exp .news-item__title b {
    max-height: 26px;
    overflow: hidden;
  }
}

.news-item__image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  max-width: 420px;
  height: 248px;
  margin: 0 auto;
}

@media only screen and (min-width: 732px) {
  .news-item__image {
    height: 100%;
    width: 420px;
    margin: 0;
  }
}

.news-item__title {
  color: #899297;
  font-size: 20px;
  line-height: 110%;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-family: 'Bebas Neue';
  transition: color 0.5s ease;
  margin-bottom: 20px;
}

@media only screen and (min-width: 732px) {
  .news-item__title {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1160px) {
  .news-item__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1366px) {
  .news-item__title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1620px) {
  .news-item__title {
    font-size: 30px;
  }
}

.news-item__title b {
  display: block;
}

.news-item__date {
  font-size: 14px;
  font-family: 'MagistralC';
  color: #fecc00;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.news-item__date--news-page {
  margin-bottom: 2.3333333rem;
  font-weight: bold;
}

.news-item__button {
  position: absolute;
  right: 0;
  bottom: 30px;
  font-family: 'Bebas Neue';
  font-size: 20px;
  color: #2b3c90;
  line-height: 100%;
  letter-spacing: 0.05em;
  padding: 4px 64px 4px 10px;
  background-color: #fff;
  text-decoration: none;
  transition: background-color 0.5s ease, color 0.5s ease;
}

@media only screen and (min-width: 732px) {
  .news-item__button {
    font-size: 22px;
  }
}

@media only screen and (min-width: 1160px) {
  .news-item__button {
    font-size: 24px;
  }
}

.news-item__descr {
  position: relative;
  padding-left: 0;
  padding-top: 16px;
  padding-bottom: 40px;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f8f8f8;
  transition: background-color 0.5s ease;
  width: 100%;
}

@media only screen and (min-width: 732px) {
  .news-item__descr {
    padding-left: 66px;
    padding-right: 32px;
    padding-top: 70px;
  }
}

.news-item__descr .bg-holder__left {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

@media only screen and (min-width: 732px) {
  .news-item__descr .bg-holder__left {
    display: block;
  }
}

.news-item__descr .bg-holder__left:after {
  display: none;
}

.news-item__descr .bg-holder__right span, .news-item__descr .bg-holder__left span, .news-item__descr .bg-holder__center span {
  margin: 0 0 0 auto;
}

.news-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: opacity 0.5s ease;
  content: "";
}

.news-item:hover {
  z-index: 2;
}

.news-item:hover:after {
  opacity: 0;
}

@media only screen and (min-width: 732px) {
  .news-item:hover:after {
    opacity: 1;
  }
}

.news-item:hover .news-item__title {
  color: #0b3b54;
}

.news-item:hover .news-item__button {
  color: #fff;
  background-color: #0b3b54;
}

.news-item:hover .news-item__descr {
  background-color: #fff;
}

.partners-slider {
  max-width: 1156px;
  margin: 0 auto;
  width: 100%;
}

.partners-slider-wrapper {
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

@media only screen and (min-width: 1160px) {
  .partners-slider-wrapper {
    height: 90px;
  }
}

@media only screen and (min-width: 732px) {
  .partners-slider-wrapper {
    padding: 0 90px;
  }
}

.partners-slider-item {
  margin: 0;
  display: none;
}

.partners {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .partners {
    margin-bottom: 46px;
  }
}

.seo-text {
  margin-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .seo-text {
    margin-bottom: 50px;
  }
}

.sub-contacts {
  height: 50px;
  position: relative;
  z-index: 25;
}

.sub-contacts__content {
  background-color: #0b3b54;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
}

@media only screen and (min-width: 1366px) {
  .sub-contacts__content {
    padding: 30px;
  }
}

.sub-contacts__group {
  width: 250px;
  padding: 0 6px;
}

@media (max-width: 1250px) {
  .sub-contacts .title-t1 {
    font-size: 34px;
  }
}

@media (max-width: 1000px) {
  .sub-contacts {
    display: none;
  }
}

.wezom {
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 1160px) {
  .wezom {
    flex-direction: row;
  }
}

.wezom__text {
  font-family: 'Proxima Nova Rg';
  font-size: 12px;
  color: #899297;
  margin-right: 0;
  margin-top: 10px;
  order: 2;
  transition: color 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .wezom__text {
    margin-top: 0;
    order: 1;
    margin-right: 10px;
  }
}

.wezom__logo {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #d01400;
  text-decoration: none;
  transition: border-radius 0.5s ease;
  order: 1;
}

@media only screen and (min-width: 1160px) {
  .wezom__logo {
    order: 2;
  }
}

.wezom__logo:hover {
  border-radius: 100%;
}

.wezom__logo:hover .wezom__logo-holder {
  transform: scale(0.8);
}

.wezom__logo svg {
  fill: #fff;
  width: 34px;
  height: 12px;
}

.wezom__logo-holder {
  transition: transform 0.8s ease;
  transform: scale(1);
}

.wezom:hover .wezom__text {
  color: #d01400;
}

.aside {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 200px;
  background-color: #f2f2f2;
  z-index: 25;
  padding-left: 10px;
  padding-top: 110px;
  display: none;
}

@media only screen and (min-width: 1160px) {
  .aside {
    display: block;
  }
}

@media only screen and (min-width: 1620px) {
  .aside {
    width: 280px;
  }
}

.aside__404 {
  justify-content: flex-end;
  align-items: flex-end;
  padding-left: 6px;
  padding-bottom: 30px;
  display: none;
}

@media only screen and (min-width: 1160px) {
  .aside__404 {
    display: flex;
  }
}

.aside__404 .sub-nav__item {
  display: none;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url("fonts/subset-BebasNeueBold.woff2") format("woff2"), url("fonts/subset-BebasNeueBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url("fonts/subset-BebasNeueLight.woff2") format("woff2"), url("fonts/subset-BebasNeueLight.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url("fonts/subset-BebasNeueRegular.woff2") format("woff2"), url("fonts/subset-BebasNeueRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MagistralC';
  src: url("fonts/subset-MagistralC-Bold.woff2") format("woff2"), url("fonts/subset-MagistralC-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("fonts/subset-ProximaNova-Regular.woff2") format("woff2"), url("fonts/subset-ProximaNova-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MagistralC';
  src: url("fonts/subset-MagistralC.woff2") format("woff2"), url("fonts/subset-MagistralC.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Rg';
  src: url("fonts/subset-ProximaNova-Light.woff2") format("woff2"), url("fonts/subset-ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

.contacts-phones {
  display: flex;
  flex-direction: column;
  padding: 0 6px;
}

.contacts-phones__link {
  text-decoration: none;
  letter-spacing: 0.1em;
}

.contacts-phones__link--sub-contacts {
  color: #fff;
}

.contacts-phones__link--main {
  color: #0b3b54;
}

.contacts-addres {
  line-height: 1.2em;
}

.contacts-addres--sub-contacts {
  color: #fff;
}

.contacts-addres--main {
  color: #0b3b54;
  max-width: 290px;
}

.comtacts-mail__item--sub-contacts {
  color: #fff;
}

.comtacts-mail__item--main {
  color: #0b3b54;
}

.contacts-soc__title--sub-contacts {
  color: #fff;
  margin-bottom: 14px;
  max-width: 290px;
}

.contacts-soc__title--main {
  color: #899297;
  margin-bottom: 24px;
}

.contacts-main-block {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1023px) {
  .contacts-main-block {
    justify-content: space-between;
    margin-bottom: 70px;
  }
}

.contacts-main-block__title {
  font-weight: bold;
  font-family: 'MagistralC';
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 14px;
  color: #899297;
}

@media only screen and (min-width: 1160px) {
  .contacts-main-block__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
}

.contacts-main-block .contacts-soc {
  width: 100%;
  max-width: 100%;
}

@media only screen and (min-width: 732px) {
  .contacts-main-block .contacts-soc {
    width: 50%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1023px) {
  .contacts-main-block .contacts-soc {
    width: auto;
    max-width: 270px;
  }
}

.contacts-main-block .soc-block {
  max-width: 270px;
  justify-content: flex-start;
}

@media only screen and (min-width: 1023px) {
  .contacts-main-block .soc-block {
    justify-content: center;
  }
}

.contacts-sblock {
  padding-right: 10px;
  width: 100%;
  margin-bottom: 20px;
  max-width: 100%;
}

.contacts-sblock .contacts-phones {
  padding: 0;
}

@media only screen and (min-width: 1023px) {
  .contacts-sblock {
    max-width: 290px;
    width: 20%;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 732px) {
  .contacts-sblock {
    width: 50%;
    max-width: 100%;
  }
}

.objects {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 48px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

@media only screen and (min-width: 1366px) {
  .objects {
    padding-bottom: 100px;
  }
}

.objects__title {
  margin-right: 2vw;
  text-align: center;
}

@media (max-width: 767px) {
  .objects {
    background-image: none !important;
  }
}

.objects-slider {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

.objects-slider .slick-list {
  padding: 10px 10px;
}

.objects-slider-wrapper {
  position: relative;
  max-width: 1460px;
  margin: 0 auto 20px auto;
  width: 100%;
}

@media only screen and (min-width: 1160px) {
  .objects-slider-wrapper {
    margin: 0 auto 50px auto;
  }
}

.slick-slide {
  outline: none;
}

.objects-slider-item {
  width: 460px;
  height: 350px;
  position: relative;
  text-decoration: none;
  display: block;
  margin: 10px;
  display: none;
}

@media only screen and (min-width: 769px) {
  .objects-slider-item {
    height: 300px;
  }
}

@media only screen and (min-width: 1620px) {
  .objects-slider-item {
    width: 460px;
    height: 350px;
  }
}

.objects-slider-item__wrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.objects-slider-item__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: transform 1s ease;
  background-size: cover;
  z-index: 4;
}

.objects-slider-item__image:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #14384b;
  opacity: 0.5;
}

.objects-slider-item__descr {
  z-index: 5;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  transition: color 0.5s ease;
}

.objects-slider-item__name {
  text-align: center;
}

.objects-slider-item__name, .objects-slider-item__date {
  font-size: 22px;
  letter-spacing: 0.1em;
}

@media only screen and (min-width: 769px) {
  .objects-slider-item__name, .objects-slider-item__date {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1620px) {
  .objects-slider-item__name, .objects-slider-item__date {
    font-size: 30px;
  }
}

.objects-slider-item__date {
  font-weight: 300;
}

.objects-slider-item:after {
  box-shadow: 0px 8px 34px -3px rgba(0, 0, 0, 0.75);
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease;
}

.objects-slider-item:hover .objects-slider-item__descr {
  color: #fecc00;
}

.objects-slider-item:hover .objects-slider-item__image {
  transform: scale(1.1);
}

.objects-slider-item:hover:after {
  opacity: 1;
}

.advantages {
  padding-top: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 34px;
}

.advantages__image {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 310px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}

.advantages__grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .advantages__grid {
    margin-bottom: 48px;
  }
}

.advantages-item {
  width: 266px;
  height: 248px;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center;
  overflow: hidden;
  margin: 10px;
}

.advantages-item .bg-holder__left {
  display: block;
}

.advantages-item__front, .advantages-item__end {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease, opacity 0.3s ease;
  padding: 36px 20px;
}

.advantages-item__front {
  background-color: #899297;
  width: 100%;
  z-index: 1;
}

.advantages-item__end {
  background-color: #fecc00;
  opacity: 1;
  transform: rotateX(-90deg);
  transform-origin: bottom;
  z-index: 2;
}

.advantages-item__end:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
  transition: opacity 0.3s 0s ease;
}

.advantages-item__title {
  text-align: center;
  line-height: 100%;
  margin-bottom: 30px;
}

.advantages-item__title b {
  display: block;
}

.advantages-item__title--end {
  opacity: 0;
  transition: opacity 0.3s 0s ease;
}

.advantages-item__text {
  text-align: center;
  font-size: 16px;
  color: #0b3b54;
  line-height: 24px;
  overflow: hidden;
  height: 96px;
  opacity: 0;
  transition: opacity 0.3s 0s ease;
}

.advantages-item__end-title-inner span {
  position: relative;
  display: inline-block;
  color: #fecc00;
  line-height: 100%;
}

.advantages-item__end-title-inner span:before {
  position: absolute;
  left: -30px;
  top: 0;
  bottom: 0;
  content: "";
  margin: 0 auto;
  right: 0;
  width: calc(100% + 60px);
  height: 100%;
  background-color: #0b3b54;
  z-index: -1;
}

.advantages-item:hover .advantages-item__end {
  transform: rotateY(0);
}

.advantages-item:hover .advantages-item__end:after {
  opacity: 1;
  transition: opacity 0.5s 0.1s ease;
}

.advantages-item:hover .advantages-item__title--end,
.advantages-item:hover .advantages-item__text {
  opacity: 1;
  transition: opacity 0.5s ease;
}

@media (max-width: 1722px) {
  .advantages-item__end {
    padding: 14px 8px;
  }
  .advantages-item {
    margin: 6px;
    width: 220px;
    height: 218px;
  }
  .advantages-item__title {
    font-size: 24px;
  }
  .advantages-item__text {
    line-height: 18px;
  }
}

.phones {
  position: relative;
  display: none;
  flex-wrap: nowrap;
}

@media only screen and (min-width: 1160px) {
  .phones {
    display: flex;
  }
}

.phones__item {
  color: #0b3b54;
  text-decoration: none;
  white-space: nowrap;
  font-size: 20px;
  display: inline-block;
}

@media only screen and (min-width: 1620px) {
  .phones__item {
    font-size: 24px;
  }
}

.phones__arrow {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  display: inline-block;
  user-select: none;
  margin: 0 6px 0 18px;
}

.phones__arrow:after {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  width: 10px;
  height: 10px;
  border-left: 1px solid #899297;
  border-bottom: 1px solid #899297;
  transform: rotate(-45deg);
  transition: border-color 0.5s ease;
}

.phones__arrow:hover:after {
  border-color: #0b3b54;
}

.phones__more {
  position: absolute;
  display: block;
  padding: 0 16px;
  background-color: #fff;
  text-align: center;
  min-width: 180px;
  left: 0;
  top: 44px;
  max-height: 0px;
  transition: padding 0.6s ease, max-height 0.6s ease;
  overflow: hidden;
}

@media only screen and (min-width: 1160px) {
  .phones__more {
    top: 40px;
    min-width: 225px;
    left: -40px;
  }
}

@media only screen and (min-width: 1620px) {
  .phones__more {
    top: 68px;
  }
}

.phones__more.is-active {
  padding: 16px;
  max-height: 1000px;
  transition: padding 0.6s ease, max-height 2.6s ease;
}

.to-top {
  position: fixed;
  bottom: 60px;
  left: auto;
  right: 20px;
  height: 46px;
  width: 46px;
  z-index: 255;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s cubic-bezier(1, 0.15, 1, 0.01), opacity 0.3s ease;
  display: flex;
  transform: translateY(100%);
  transform-origin: center;
  backface-visibility: hidden;
  opacity: 0;
}

@media only screen and (min-width: 1160px) {
  .to-top {
    left: 10px;
    right: auto;
    bottom: 20px;
    height: 120px;
    width: 180px;
  }
}

@media only screen and (min-width: 1620px) {
  .to-top {
    left: 50px;
  }
}

.to-top.js-active {
  transform: translateY(0%);
  opacity: 1;
  transition: transform 0.5s cubic-bezier(0.78, 0.77, 0.74, 1.15), opacity 0.5s ease;
}

.to-top .to-top__wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  transform-origin: center;
  flex-direction: column;
  width: 46px;
  border-radius: 100%;
  height: 46px;
  background-color: #fecc00;
}

@media only screen and (min-width: 1160px) {
  .to-top .to-top__wrapper {
    width: auto;
    height: auto;
    border-radius: 0;
    background-color: transparent;
  }
}

.to-top__image {
  margin-bottom: 0;
  width: 46px;
  height: 46px;
  position: relative;
}

@media only screen and (min-width: 1160px) {
  .to-top__image {
    width: 66px;
    height: 50px;
    margin-bottom: 30px;
  }
}

.to-top__text {
  text-transform: uppercase;
  font-size: 18px;
  color: #899297;
  letter-spacing: 0.05em;
  font-family: 'Bebas Neue';
  transition: color 0.5s ease;
  display: none;
}

@media only screen and (min-width: 1160px) {
  .to-top__text {
    display: block;
  }
}

.to-top__image-part-1 {
  width: 16px;
  height: 16px;
  border-right: 2px solid #0b3b54;
  border-top: 2px solid #0b3b54;
  position: absolute;
  top: 6px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transform: rotate(-45deg);
  z-index: 5;
}

@media only screen and (min-width: 1160px) {
  .to-top__image-part-1 {
    top: 0;
    width: 26px;
    height: 26px;
    border-right: 1px solid #0b3b54;
    border-top: 1px solid #0b3b54;
  }
}

.to-top__image-part-1:after {
  position: absolute;
  right: -1px;
  top: 0;
  content: "";
  width: 1px;
  display: none;
  height: 38px;
  background-color: #0b3b54;
  transform-origin: top;
  transform: rotate(45deg);
}

@media only screen and (min-width: 1160px) {
  .to-top__image-part-1:after {
    display: block;
  }
}

.to-top__image-part-2 {
  width: 12px;
  height: 12px;
  position: absolute;
  left: -1px;
  top: 2px;
  right: 0;
  margin: 0 auto;
  content: "";
  background-color: #cacede;
  border-radius: 100%;
  z-index: 2;
  display: none;
  transition: transform 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .to-top__image-part-2 {
    display: block;
  }
}

.to-top__image-part-2:after {
  width: 42px;
  height: 42px;
  position: absolute;
  border-right: 1px solid #cacede;
  border-top: 1px solid #cacede;
  transform: rotate(-45deg);
  top: 13px;
  left: -15px;
  right: 0;
  margin: 0 auto;
  content: "";
}

.to-top:hover .to-top__text {
  color: #0b3b54;
}

.to-top:hover .to-top__image-part-2 {
  transform: scale(0);
}

.search {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 50px;
  width: 50px;
  z-index: 26;
}

@media only screen and (min-width: 1160px) {
  .search {
    height: 68px;
    width: 68px;
  }
}

@media only screen and (min-width: 1366px) {
  .search {
    height: 73px;
    width: 73px;
  }
}

@media only screen and (min-width: 1620px) {
  .search {
    height: 90px;
    width: 90px;
  }
}

.search svg {
  width: 26px;
  height: 26px;
  fill: #899297;
  transition: fill 0.5s ease;
}

.search:hover svg {
  fill: #fecc00;
}

.search-block {
  position: relative;
  overflow: hidden;
  z-index: 26;
}

.search-block .custom-cross {
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease-out;
}

.search-block.is-active .custom-cross {
  transform: scaleX(1);
}

.search-form {
  position: fixed;
  margin-right: 0;
  background-color: #7f6600;
  padding: 0 10px;
  height: 50px;
  display: flex;
  align-items: center;
  z-index: 25;
  transform-origin: right;
  transform: translateX(200%) scaleX(0.2);
  opacity: 0;
  top: 64px;
  right: 0;
  transition: transform 0.5s 0.2s cubic-bezier(0.76, -0.35, 0.55, 0.88), opacity 1.5s 0.1s ease;
}

@media only screen and (min-width: 1160px) {
  .search-form {
    height: 68px;
    position: absolute;
    top: 0;
    right: calc(100% - 1vw);
    margin-right: 14px;
  }
}

@media only screen and (min-width: 1366px) {
  .search-form {
    height: 73px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 1620px) {
  .search-form {
    right: calc(100% - 2.4vw);
    margin-right: 1px;
    height: 90px;
    padding: 0 20px;
  }
}

.search-form .control-holder {
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-form .form__group {
  width: 100%;
  padding-right: 10px;
}

@media only screen and (min-width: 1620px) {
  .search-form .form__group {
    padding-right: 20px;
  }
}

.search-form .button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.search-form.is-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 0.3s 0.2s ease, opacity 0.3s 0.3s ease;
}

.search-form.is-active .control-holder {
  opacity: 1;
  transition: opacity 0.4s 0.5s ease;
}

.search-form.is-active .button {
  opacity: 1;
  transition: opacity 0.4s 0.5s ease;
}

.sub-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 20px;
  transition: max-height 1.5s ease;
  overflow-y: scroll;
}

.sub-nav.js-halper {
  transition: max-height 0.5s ease;
  margin-bottom: 20px;
}

.sub-nav__item {
  padding: 0;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1620px) {
  .sub-nav__item {
    padding: 0 40px;
    margin-bottom: 30px;
  }
}

.sub-nav__link {
  display: block;
  text-align: center;
  text-decoration: none;
  color: #899297;
  font-size: 18px;
  transition: color 0.5s ease;
  letter-spacing: 0.1em;
}

.sub-nav__link:hover {
  color: #0b3b54;
}

.sub-nav__link:hover .sub-nav__link-image svg {
  fill: #0b3b54;
}

.sub-nav__link:hover .sub-nav__link-tex:after {
  background-color: #0b3b54;
}

.sub-nav__link-image {
  width: 70px;
  height: 68px;
  position: relative;
  display: block;
  margin: 0 auto;
  margin-bottom: 4px;
}

.sub-nav__link-image svg {
  max-width: 100%;
  transition: fill 0.5s ease;
  max-height: 100%;
  fill: #899297;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.sub-nav__link-text {
  display: inline-block;
  position: relative;
  font-weight: bold;
  padding-bottom: 2px;
  text-transform: uppercase;
}

.sub-nav__link-text:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  background-color: #899297;
  width: 100%;
  transition: background-color 0.5s ease;
  height: 1px;
}

.main-block {
  width: 100%;
  margin-bottom: 10px;
}

@media only screen and (min-width: 1160px) {
  .main-block {
    margin-bottom: 30px;
  }
}

.main-block__grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .main-block__grid {
    margin-bottom: 40px;
  }
}

.main-block-item {
  position: relative;
  height: calc(50vh - 32px);
  width: 100%;
  padding: 20px 16px;
  text-decoration: none;
  overflow: hidden;
  max-height: 326px;
}

@media only screen and (min-width: 768px) {
  .main-block-item {
    width: 50%;
    height: 326px;
    padding: 50px 40px;
  }
}

@media only screen and (min-width: 1160px) {
  .main-block-item {
    height: calc(50vh - 55px);
  }
}

.main-block-item:hover .main-block-item__image {
  transform: scale(1.2);
  transition: transform 10s ease;
}

.main-block-item:hover .main-block-item__text {
  color: #fecc00;
}

.main-block-item:hover .bg-holder__left {
  opacity: 1;
  transform: scale(1);
}

.main-block-item__image {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: transform 6s ease;
}

.main-block-item__text {
  color: #fff;
  z-index: 25;
  position: relative;
  text-transform: uppercase;
  transition: color 0.5s ease;
  line-height: 100%;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-size: 30px;
}

@media only screen and (min-width: 769px) {
  .main-block-item__text {
    font-size: 40px;
  }
}

@media only screen and (min-width: 1160px) {
  .main-block-item__text {
    font-size: 48px;
  }
}

.main-block-item__text b {
  display: block;
}

.main-block-item__text:after {
  position: absolute;
  content: "";
  left: 4px;
  bottom: calc(100% + 6px);
  height: 48px;
  width: 1px;
  background-color: #fecc00;
}

.main-block-item__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.main-block-item__overlay:before, .main-block-item__overlay:after {
  content: "";
  position: absolute;
}

.main-block-item__overlay:after {
  opacity: 0.5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.main-block-item__overlay:before {
  top: -220px;
  left: -220px;
  width: 440px;
  height: 440px;
  z-index: 1;
  background: radial-gradient(ellipse at center, rgba(254, 204, 0, 0.5), rgba(254, 204, 0, 0.3), rgba(254, 204, 0, 0.1), transparent, transparent);
}

.main-block-item__overlay .bg-holder__left {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.3s 0.2s ease;
  transform: scale(0.8);
  z-index: 26;
}

.main-block-item__overlay .bg-holder__left--advantages span {
  top: 0;
}

.soc-block {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.soc-block__item {
  margin-right: 6px;
  margin-bottom: 6px;
}

@media only screen and (min-width: 1620px) {
  .soc-block__item {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.soc-block__link {
  border: 1px solid #899297;
  display: block;
  width: 30px;
  height: 38px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1620px) {
  .soc-block__link {
    width: 44px;
    height: 50px;
  }
}

.soc-block__link svg {
  max-height: 16px;
  max-width: 18px;
  fill: #899297;
  position: relative;
  z-index: 1;
  transition: fill 0.3s ease;
}

.soc-block__link:after {
  position: absolute;
  content: "";
  bottom: -2px;
  left: -1px;
  width: calc(100% + 2px);
  height: 2px;
  background-color: #b5c4cb;
  z-index: 0;
  transition: background-color 0.3s ease, bottom 0.5s ease, height 0.5s ease;
}

.soc-block__link--fb:hover:after {
  background-color: #3b5998;
}

.soc-block__link--vk:hover:after {
  background-color: #2f79b2;
}

.soc-block__link--inst:hover:after {
  background-color: #c42b66;
}

.soc-block__link--gp:hover:after {
  background-color: #e53f30;
}

.soc-block__link--ok:hover:after {
  background-color: #ff9600;
}

.soc-block__link--sub-contacts {
  border-color: #3e6478;
}

.soc-block__link--sub-contacts svg {
  fill: #fff;
}

.soc-block__link--sub-contacts:after {
  background-color: #3e6478;
}

.soc-block__link:hover svg {
  fill: #fff;
}

.soc-block__link:hover:after {
  height: calc(100% + 2px);
  bottom: -1px;
}

.slider-arrow {
  width: 90px;
  height: 90px;
  background-color: #899297;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.slider-arrow--left {
  left: 0;
  display: none;
}

@media only screen and (min-width: 732px) {
  .slider-arrow--left {
    display: block;
  }
}

.slider-arrow--right {
  right: 0;
  display: none;
}

@media only screen and (min-width: 732px) {
  .slider-arrow--right {
    display: block;
  }
}

.slider-arrow:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fecc00;
  transform: scaleY(0);
  content: "";
  transition: transform 0.5s ease;
  transform-origin: bottom;
}

.slider-arrow:hover:before {
  transform: scaleY(1);
}

.slider-arrow:hover .arrow-image-wrapper__image-part-1 {
  border-color: #0b3b54;
}

.slider-arrow:hover .arrow-image-wrapper__image-part-1:after {
  background-color: #0b3b54;
}

.slider-arrow:hover .arrow-image-wrapper__image-part-2 {
  background-color: #d4af1d;
}

.slider-arrow:hover .arrow-image-wrapper__image-part-2:after {
  border-color: #d4af1d;
}

.slider-arrow:active .arrow-image-wrapper--rotate-left {
  transform: rotate(-90deg) scale(0.8);
  transition: transform 0s ease;
}

.slider-arrow:active .arrow-image-wrapper--rotate-right {
  transform: rotate(90deg) scale(0.8);
  transition: transform 0s ease;
}

.slider-arrow:active .arrow-image-wrapper__image-part-2 {
  transform: scale(0.6);
  transition: transform 0s ease;
}

.arrow-image-wrapper {
  position: relative;
  z-index: 5;
}

.arrow-image-wrapper--rotate-right {
  transform: rotate(90deg);
  transition: transform 0.5s ease;
}

.arrow-image-wrapper--rotate-left {
  transform: rotate(-90deg);
  transition: transform 0.5s ease;
}

.arrow-image-wrapper--rotate-right, .arrow-image-wrapper--rotate-left {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
}

.arrow-image-wrapper__image-part-1 {
  width: 26px;
  height: 26px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  transform: rotate(-45deg);
  transition: border-color 0.5s ease;
  z-index: 5;
}

.arrow-image-wrapper__image-part-1:after {
  position: absolute;
  right: -1px;
  top: 0;
  content: "";
  width: 1px;
  height: 38px;
  background-color: #fff;
  transform-origin: top;
  transform: rotate(45deg);
  transition: background-color 0.5s ease;
}

.arrow-image-wrapper__image-part-2 {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 1px;
  top: 22px;
  right: 0;
  margin: 0 auto;
  content: "";
  background-color: #cacede;
  border-radius: 100%;
  z-index: 2;
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.arrow-image-wrapper__image-part-2:after {
  width: 42px;
  height: 42px;
  position: absolute;
  border-right: 1px solid #cacede;
  border-top: 1px solid #cacede;
  transform: rotate(-45deg);
  top: 13px;
  left: -15px;
  right: 0;
  margin: 0 auto;
  content: "";
  transition: border-color 0.5s ease;
}

.slider-arrow-group {
  width: 182px;
  position: absolute;
  bottom: 22px;
  right: 28px;
  height: 90px;
  z-index: 5;
}

.partners-slider-item img {
  margin: 0 auto;
}

.wrk-content {
  padding: 40px 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1160px) {
  .wrk-content {
    margin-bottom: 60px;
  }
}

.wrk-content__grid {
  display: flex;
  justify-content: center;
  padding: 0 30px;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1366px) {
  .wrk-content__grid {
    flex-wrap: nowrap;
  }
}

.wrk-item {
  width: 200px;
  margin-right: 14px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1366px) {
  .wrk-item {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 1620px) {
  .wrk-item {
    margin-right: 22px;
  }
}

.wrk-item__top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 40px;
  position: relative;
  height: 82px;
}

.wrk-item__top:before, .wrk-item__top:after {
  position: absolute;
  content: "";
  background-color: #fecc00;
}

.wrk-item__top:before {
  left: -8px;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  bottom: -27px;
}

.wrk-item__top:after {
  left: 0;
  width: 100%;
  height: 1px;
  bottom: -20px;
}

.wrk-item__image svg {
  fill: #8d9fa9;
  max-width: 100%;
  max-height: 100%;
}

.wrk-item__title, .wrk-item__time {
  color: #fff;
  font-size: 22px;
  line-height: 100%;
}

@media only screen and (min-width: 1160px) {
  .wrk-item__title, .wrk-item__time {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1620px) {
  .wrk-item__title, .wrk-item__time {
    font-size: 30px;
  }
}

.wrk-item__time {
  font-weight: 300;
  margin-bottom: 10px;
}

.wrk-item__text {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6em;
  color: #fff;
}

@media only screen and (min-width: 1620px) {
  .wrk-item__text {
    font-size: 16px;
  }
}

.wrk-item__number {
  font-size: 70px;
  color: #fecc00;
  line-height: 50px;
  margin-right: 24px;
}

.wrk-item:nth-child(1) .wrk-item__image {
  width: 66px;
  height: 66px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(1) .wrk-item__image {
    width: 80px;
    height: 80px;
  }
}

.wrk-item:nth-child(2) .wrk-item__image {
  width: 66px;
  height: 66px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(2) .wrk-item__image {
    width: 80px;
    height: 80px;
  }
}

.wrk-item:nth-child(3) .wrk-item__image {
  width: 64px;
  height: 64px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(3) .wrk-item__image {
    width: 78px;
    height: 78px;
  }
}

.wrk-item:nth-child(4) .wrk-item__image {
  width: 66px;
  height: 40px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(4) .wrk-item__image {
    width: 80px;
    height: 66px;
  }
}

.wrk-item:nth-child(5) .wrk-item__image {
  width: 66px;
  height: 66px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(5) .wrk-item__image {
    width: 80px;
    height: 80px;
  }
}

.wrk-item:nth-child(6) .wrk-item__image {
  width: 66px;
  height: 66px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(6) .wrk-item__image {
    width: 80px;
    height: 80px;
  }
}

.wrk-item:nth-child(7) {
  margin-right: 0;
}

.wrk-item:nth-child(7) .wrk-item__image {
  width: 66px;
  height: 66px;
}

@media only screen and (min-width: 769px) {
  .wrk-item:nth-child(7) .wrk-item__image {
    width: 80px;
    height: 80px;
  }
}

.button {
  display: inline-block;
  height: 40px;
  line-height: 60px;
  outline: none;
  position: relative;
  z-index: 1;
  text-decoration: none;
  backface-visibility: hidden;
  border: none;
  padding: 0;
  transition: transform 0.3s ease;
  min-width: 300px;
  background-color: transparent;
}

@media only screen and (min-width: 769px) {
  .button {
    min-width: 460px;
  }
}

@media only screen and (min-width: 1224px) {
  .button {
    height: 60px;
  }
}

.button__content {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: normal;
  font-family: 'Bebas Neue';
  position: relative;
  letter-spacing: 1px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 100%;
  color: inherit;
  letter-spacing: 0.05em;
  transition: color 0.5s 0.2s ease;
}

@media only screen and (min-width: 1160px) {
  .button__content {
    font-size: 24px;
  }
}

.button__content span {
  line-height: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  transform: scale(1);
  opacity: 1;
  position: relative;
  z-index: 10;
  transition: transform 0.4s 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955), opacity 0.4s 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.button--disabled {
  pointer-events: none;
}

.button--primary {
  color: #2b3c90;
  border: 1px solid #2b3c90;
  background-color: #fff;
}

.button--primary:before {
  background-color: #0b3b54;
}

.button--primary:hover:after {
  background-color: #0b3b54;
}

.button--second {
  color: #2b3c90;
  border: 1px solid #2b3c90;
  background-color: #fff;
}

.button--second:after, .button--second:before {
  background-color: #fecc00;
}

.button--small {
  min-width: 100px;
}

@media only screen and (min-width: 769px) {
  .button--small {
    min-width: 180px;
  }
}

@media only screen and (min-width: 1224px) {
  .button--small {
    min-width: 300px;
  }
}

.button--head {
  width: 190px !important;
  min-width: 190px !important;
  margin: 0 10px;
  display: none;
  height: 32px !important;
  margin-bottom: 10px;
}

.button--head .button__content {
  height: 32px !important;
}

@media only screen and (min-width: 1160px) {
  .button--head {
    display: block;
  }
}

.button--w100 {
  width: 100% !important;
  min-width: 100% !important;
}

.button:hover .button__content {
  color: #fff;
  transition: color 0.5s 0.4s ease;
}

.button:hover:before {
  transform: scaleX(1);
  transition: transform 0.6s 0.2s ease-in-out;
}

.button:hover:after {
  transform: scaleY(0.1);
  transition: transform 0.5s 0s ease, background-color 0.3s 0s ease, left 0.5s 0s ease;
  left: 7px;
}

.button:before {
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  position: absolute;
  transform: scaleX(0);
  left: -1px;
  top: -1px;
  content: "";
  z-index: 5;
  transition: transform 0.6s 0s ease-in-out;
  transform-origin: left;
}

.button:after {
  position: absolute;
  content: "";
  left: 50%;
  right: 0;
  width: 14px;
  height: 14px;
  bottom: -7px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #fecc00;
  transform: scaleY(1);
  margin-left: -7px;
  z-index: 5;
  transition: transform 0.4s 0.4s ease, background-color 0.3s 0.4s ease, left 0.4s 0.4s ease;
}

.button--popup {
  color: #fff;
  border: 1px solid #2b3c90;
  background-color: #0b3b54;
}

.button--popup:after, .button--popup:before {
  background-color: #fff;
}

.button--popup:hover .button__content {
  color: #0b3b54;
}

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: opacity,-webkit-filter;
  -webkit-transition-property: opacity,-webkit-filter;
  transition-property: opacity,filter;
  transition-property: opacity,filter,-webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger:hover {
  opacity: .7;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  position: absolute;
  width: 40px;
  height: 4px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  border-radius: 4px;
}

.hamburger-inner:after, .hamburger-inner:before {
  display: block;
  content: '';
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--3dx .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx .hamburger-inner:after, .hamburger--3dx .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dx.is-active .hamburger-inner {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: transparent;
}

.hamburger--3dx.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dx-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dx-r .hamburger-inner:after, .hamburger--3dx-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dx-r.is-active .hamburger-inner {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  background-color: transparent;
}

.hamburger--3dx-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dx-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy .hamburger-inner:after, .hamburger--3dy .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dy.is-active .hamburger-inner {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  background-color: transparent;
}

.hamburger--3dy.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 80px;
  perspective: 80px;
}

.hamburger--3dy-r .hamburger-inner {
  transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger--3dy-r .hamburger-inner:after, .hamburger--3dy-r .hamburger-inner:before {
  transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  -webkit-transition: -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
  transition: transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s, -webkit-transform 0s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
}

.hamburger--3dy-r.is-active .hamburger-inner {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  background-color: transparent;
}

.hamburger--3dy-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--3dy-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--arrow.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrow.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:before {
  -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrow-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 0, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt .hamburger-inner:before {
  transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt .hamburger-inner:after {
  transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(-8px, -10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--arrowalt.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(-8px, 10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r .hamburger-inner:before {
  transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: top 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r .hamburger-inner:after {
  transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  -webkit-transition: bottom 0.1s ease 0.15s, -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s ease 0.15s, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: top 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: top 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
  transform: translate3d(8px, -10px, 0) rotate(45deg) scaleX(0.7);
}

.hamburger--arrowalt-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  transition: bottom 0.1s ease, transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s, -webkit-transform 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22) 0.1s;
  -webkit-transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
  transform: translate3d(8px, 10px, 0) rotate(-45deg) scaleX(0.7);
}

.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner:after, .hamburger--boring .hamburger-inner:before {
  -webkit-transition-property: none;
  transition-property: none;
}

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--boring.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--boring.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--collapse .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  opacity: 0;
}

.hamburger--collapse.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: .15s;
  transition-delay: .15s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--collapse-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0.1s linear;
}

.hamburger--collapse-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
  transform: translate3d(0, -10px, 0) rotate(45deg);
}

.hamburger--collapse-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s linear 0.27s;
  opacity: 0;
}

.hamburger--collapse-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.42s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.hamburger--elastic .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s;
}

.hamburger--elastic .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
  transform: translate3d(0, 10px, 0) rotate(135deg);
}

.hamburger--elastic.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
  transform: translate3d(0, -20px, 0) rotate(-270deg);
}

.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

.hamburger--elastic-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition: opacity .15s ease .4s;
  transition: opacity .15s ease .4s;
}

.hamburger--elastic-r .hamburger-inner:after {
  top: 20px;
  transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
  transform: translate3d(0, 10px, 0) rotate(-135deg);
}

.hamburger--elastic-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  opacity: 0;
}

.hamburger--elastic-r.is-active .hamburger-inner:after {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
  transform: translate3d(0, -20px, 0) rotate(270deg);
}

.hamburger--emphatic {
  overflow: hidden;
}

.hamburger--emphatic .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s;
}

.hamburger--emphatic .hamburger-inner:before {
  left: 0;
  transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic.is-active .hamburger-inner:before {
  top: -80px;
  left: -80px;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(80px, 80px, 0) rotate(45deg);
  transform: translate3d(80px, 80px, 0) rotate(45deg);
}

.hamburger--emphatic.is-active .hamburger-inner:after {
  top: -80px;
  right: -80px;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(-80px, 80px, 0) rotate(-45deg);
  transform: translate3d(-80px, 80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r {
  overflow: hidden;
}

.hamburger--emphatic-r .hamburger-inner {
  -webkit-transition: background-color .2s ease-in .25s;
  transition: background-color .2s ease-in .25s;
}

.hamburger--emphatic-r .hamburger-inner:before {
  left: 0;
  transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, left 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r .hamburger-inner:after {
  top: 10px;
  right: 0;
  transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  -webkit-transition: top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s linear 0.2s, right 0.2s ease-in 0.25s, -webkit-transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
}

.hamburger--emphatic-r.is-active .hamburger-inner {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger--emphatic-r.is-active .hamburger-inner:before {
  top: 80px;
  left: -80px;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: left 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: left 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(80px, -80px, 0) rotate(-45deg);
  transform: translate3d(80px, -80px, 0) rotate(-45deg);
}

.hamburger--emphatic-r.is-active .hamburger-inner:after {
  top: 80px;
  right: -80px;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transition: right 0.2s ease-out, top 0.05s linear 0.2s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  transition: right 0.2s ease-out, top 0.05s linear 0.2s, transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s, -webkit-transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
  -webkit-transform: translate3d(-80px, -80px, 0) rotate(45deg);
  transform: translate3d(-80px, -80px, 0) rotate(45deg);
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity,-webkit-transform;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.hamburger--slider .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
  transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.hamburger--slider-r .hamburger-inner {
  top: 2px;
}

.hamburger--slider-r .hamburger-inner:before {
  top: 10px;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  transition-property: opacity,-webkit-transform;
  -webkit-transition-property: opacity,-webkit-transform;
  transition-property: transform,opacity;
  transition-property: transform,opacity,-webkit-transform;
}

.hamburger--slider-r .hamburger-inner:after {
  top: 20px;
}

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--slider-r.is-active .hamburger-inner:before {
  -webkit-transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider-r.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
  transform: translate3d(0, -20px, 0) rotate(90deg);
}

.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s linear .15s;
  transition: background-color 0s linear .15s;
}

.hamburger--spring .hamburger-inner:before {
  top: 10px;
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring .hamburger-inner:after {
  top: 20px;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  background-color: transparent;
}

.hamburger--spring.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
  transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--spring.is-active .hamburger-inner:after {
  top: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
  transform: translate3d(0, 10px, 0) rotate(-45deg);
}

.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .15s;
  transition-duration: .15s;
}

.hamburger--spring-r .hamburger-inner:after {
  top: -20px;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0s linear;
  transition: top 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, opacity 0s linear;
}

.hamburger--spring-r .hamburger-inner:before {
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.3s, transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transition-delay: .32s;
  transition-delay: .32s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.hamburger--spring-r.is-active .hamburger-inner:after {
  top: 0;
  -webkit-transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.32s;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s linear 0.32s;
  opacity: 0;
}

.hamburger--spring-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  transition: top 0.12s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.18s, transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s, -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0.32s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--stand .hamburger-inner {
  transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  -webkit-transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
}

.hamburger--stand .hamburger-inner:before {
  transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand.is-active .hamburger-inner {
  transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: transparent;
}

.hamburger--stand.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--stand-r .hamburger-inner {
  transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  -webkit-transition: background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s;
  transition: transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s, background-color 0s linear 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0.22s;
}

.hamburger--stand-r .hamburger-inner:before {
  transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: top 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: top 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  -webkit-transition: bottom 0.1s ease-in 0.1s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transition: bottom 0.1s ease-in 0.1s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
}

.hamburger--stand-r.is-active .hamburger-inner {
  transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transition: background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s;
  transition: transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, background-color 0s linear 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background-color: transparent;
}

.hamburger--stand-r.is-active .hamburger-inner:before {
  top: 0;
  transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: top 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: top 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.hamburger--stand-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transition: bottom 0.1s ease-out 0.12s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transition: bottom 0.1s ease-out 0.12s, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--spin .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--spin .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s,opacity .1s ease-in;
  transition: top .1s ease-in .34s,opacity .1s ease-in;
}

.hamburger--spin .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.hamburger--spin.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out,opacity .1s ease-out .14s;
  transition: top .1s ease-out,opacity .1s ease-out .14s;
  opacity: 0;
}

.hamburger--spin.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--spin-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--spin-r .hamburger-inner:before {
  -webkit-transition: top .1s ease-in .34s,opacity .1s ease-in;
  transition: top .1s ease-in .34s,opacity .1s ease-in;
}

.hamburger--spin-r .hamburger-inner:after {
  transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease-in 0.34s, -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease-in 0.34s, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(-225deg);
  transform: rotate(-225deg);
}

.hamburger--spin-r.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease-out,opacity .1s ease-out .14s;
  transition: top .1s ease-out,opacity .1s ease-out .14s;
  opacity: 0;
}

.hamburger--spin-r.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease-out, transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--squeeze .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition-duration: .1s;
  transition-duration: .1s;
}

.hamburger--squeeze .hamburger-inner:before {
  -webkit-transition: top .1s ease .14s,opacity .1s ease;
  transition: top .1s ease .14s,opacity .1s ease;
}

.hamburger--squeeze .hamburger-inner:after {
  transition: bottom 0.1s ease 0.14s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -webkit-transition: bottom 0.1s ease 0.14s, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transition-delay: .14s;
  transition-delay: .14s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hamburger--squeeze.is-active .hamburger-inner:before {
  top: 0;
  -webkit-transition: top .1s ease,opacity .1s ease .14s;
  transition: top .1s ease,opacity .1s ease .14s;
  opacity: 0;
}

.hamburger--squeeze.is-active .hamburger-inner:after {
  bottom: 0;
  transition: bottom 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s, -webkit-transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger--vortex .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--vortex .hamburger-inner:after, .hamburger--vortex .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex .hamburger-inner:before {
  -webkit-transition-property: top,opacity;
  transition-property: top,opacity;
}

.hamburger--vortex .hamburger-inner:after {
  transition-property: bottom,-webkit-transform;
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
  transition-property: bottom,transform,-webkit-transform;
}

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(765deg);
  transform: rotate(765deg);
}

.hamburger--vortex.is-active .hamburger-inner:after, .hamburger--vortex.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

.hamburger--vortex-r .hamburger-inner:after, .hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-delay: .1s;
  transition-delay: .1s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
}

.hamburger--vortex-r .hamburger-inner:before {
  -webkit-transition-property: top,opacity;
  transition-property: top,opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom,-webkit-transform;
  -webkit-transition-property: bottom,-webkit-transform;
  transition-property: bottom,transform;
  transition-property: bottom,transform,-webkit-transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: rotate(-765deg);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:after, .hamburger--vortex-r.is-active .hamburger-inner:before {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
  background-color: #0b3b54;
}

.hamburger {
  padding: 10px;
  align-items: center;
  display: flex;
}

@media only screen and (min-width: 1160px) {
  .hamburger {
    display: none;
  }
}

.bg-holder {
  margin-right: 2vw;
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  width: calc(100% - 2vw);
}

.bg-holder__right, .bg-holder__left, .bg-holder__center {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 50%;
  display: none;
}

@media only screen and (min-width: 500px) {
  .bg-holder__right, .bg-holder__left, .bg-holder__center {
    display: block;
  }
}

.bg-holder__right:after, .bg-holder__left:after, .bg-holder__center:after {
  position: absolute;
  content: "";
  width: 27vw;
  background-color: #d6d6d6;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 1px;
}

.bg-holder__right span, .bg-holder__left span, .bg-holder__center span {
  width: 88px;
  height: 88px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 5;
}

.bg-holder__right span:after, .bg-holder__left span:after, .bg-holder__center span:after {
  position: absolute;
  content: "";
  background-color: #ececec;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.bg-holder__right span:before, .bg-holder__left span:before, .bg-holder__center span:before {
  position: absolute;
  content: "";
  background-color: #ececec;
  height: 1px;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.bg-holder__right span i, .bg-holder__left span i, .bg-holder__center span i {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 11px;
  height: 11px;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid #d4d4d4;
}

.bg-holder__right span i:before, .bg-holder__left span i:before, .bg-holder__center span i:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #d8d8d8;
  width: 1px;
  height: 50px;
  content: "";
  z-index: 5;
}

.bg-holder__right span i:after, .bg-holder__left span i:after, .bg-holder__center span i:after {
  position: absolute;
  left: -22px;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #a2a2a2;
  height: 1px;
  width: 50px;
  content: "";
  z-index: 5;
}

.bg-holder--abus {
  margin-bottom: 40px;
}

.bg-holder__left {
  left: 0;
}

.bg-holder__left:after {
  left: 0;
}

.bg-holder__left span {
  left: 0;
}

.bg-holder__left--advantages {
  right: 0;
  margin: auto;
}

.bg-holder__left--advantages:after {
  display: none;
}

.bg-holder__left--advantages span {
  right: 0;
  margin: auto;
  top: 80px;
}

.bg-holder__left--advantages span:before, .bg-holder__left--advantages span:after {
  background-color: #a1a8ac;
}

.bg-holder__left--advantages span i {
  border-color: #bfc4c7;
}

.bg-holder__left--advantages span i:before, .bg-holder__left--advantages span i:after {
  background-color: #fff;
}

.bg-holder__center {
  right: 0;
  left: 0;
  margin: auto;
}

.bg-holder__center:after {
  right: 0;
  left: 0;
  margin: auto;
}

.bg-holder__center span {
  right: 0;
  left: 0;
  margin: auto;
}

.bg-holder__right {
  right: 0;
}

.bg-holder__right:after {
  right: 0;
}

.bg-holder__right span {
  right: 0;
}

.bg-holder--white-exp .bg-holder__right span i:before, .bg-holder--white-exp .bg-holder__left span i:before, .bg-holder--white-exp .bg-holder__center span i:before
.bg-holder__right span i:after, .bg-holder--white-exp .bg-holder__left span i:after, .bg-holder--white-exp .bg-holder__center span i:after {
  background-color: #fff;
}

.bg-holder--white-exp .bg-holder__right span:before, .bg-holder--white-exp .bg-holder__left span:before, .bg-holder--white-exp .bg-holder__center span:before,
.bg-holder--white-exp .bg-holder__right span:after, .bg-holder--white-exp .bg-holder__left span:after, .bg-holder--white-exp .bg-holder__center span:after {
  background-color: #2c4451;
}

.bg-holder--orange .bg-holder__right:after,
.bg-holder--orange .bg-holder__left:after,
.bg-holder--orange .bg-holder__center:after {
  background-color: #fecc00;
}

.bg-holder--wus {
  position: absolute;
  left: 0;
  bottom: -50px;
  margin-right: 0;
  width: 100%;
}

@media only screen and (min-width: 1620px) {
  .bg-holder--wus {
    margin-right: 2vw;
    width: calc(100% - 2vw);
  }
}

.bg-holder--w100 .bg-holder__right:after,
.bg-holder--w100 .bg-holder__left:after {
  width: 100%;
}

.bg-holder--footer {
  position: absolute;
  top: 36px;
  left: 0;
  display: none;
}

@media only screen and (min-width: 1160px) {
  .bg-holder--footer {
    display: block;
  }
}

body::-webkit-scrollbar-corner, body *::-webkit-scrollbar-corner {
  background-color: transparent;
  display: none;
}

body::-webkit-scrollbar, body *::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

body::-webkit-scrollbar-thumb, body *::-webkit-scrollbar-thumb {
  background-color: #0b3b54;
  border: 2px solid #0b3b54;
  border-radius: 36px;
}

body::-webkit-scrollbar-track, body *::-webkit-scrollbar-track {
  background-color: transparent;
}

.main-nav {
  list-style-type: none;
  display: none;
  height: 70px;
  align-items: center;
  padding: 0;
  margin: 0;
  z-index: 25;
  position: relative;
  transition: opacity 0.3s 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .main-nav {
    display: flex;
  }
}

@media only screen and (min-width: 1620px) {
  .main-nav {
    height: 90px;
  }
}

.main-nav__item {
  height: 100%;
}

.main-nav--exp {
  border-top: 1px solid #d6d6d6;
}

.main-nav--footer {
  max-width: 100%;
  transform: translateX(-20px);
  margin: 0 auto;
  justify-content: center;
  padding-right: 34px;
  flex-wrap: wrap;
  height: auto;
  min-height: 90px;
}

.main-nav--footer .main-nav__link {
  padding: 0 16px;
  letter-spacing: 0.06em;
}

.main-nav--footer .main-nav__item {
  height: auto;
}

.main-nav__link {
  padding: 0 10px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  color: #899297;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.5s ease;
  letter-spacing: 0.1em;
  white-space: nowrap;
  font-size: 18px;
}

@media only screen and (min-width: 1224px) {
  .main-nav__link {
    padding: 0 12px;
    font-size: 20px;
  }
}

@media only screen and (min-width: 1620px) {
  .main-nav__link {
    padding: 0 22px;
    font-size: 24px;
  }
}

.main-nav__link span {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
}

.main-nav__link span:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #0b3b54;
  transform-origin: left;
  transform: rotateY(90deg);
  transition: transform 0.5s ease;
}

.main-nav__link:hover {
  color: #0b3b54;
}

.main-nav__link:hover span:after {
  transform: rotateY(0deg);
}

.main-nav__link.is-active {
  pointer-events: none;
  color: #0b3b54;
}

.main-nav__link.is-active span:after {
  transform: rotateY(0deg);
}

.main-nav.is-disabled {
  opacity: 0;
  transition: opacity 0.3s ease;
}

@media (max-width: 1880px) {
  .main-nav--footer {
    padding-bottom: 20px;
  }
}

._fwb {
  font-weight: bold;
}

._fz18 {
  font-size: 18px !important;
}

._fz24 {
  font-size: 20px;
}

@media only screen and (min-width: 1366px) {
  ._fz24 {
    font-size: 24px;
  }
}

._fz48 {
  font-size: 48px;
}

._center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

._tac {
  text-align: center;
}

._c-white {
  color: #fff;
}

._columns-2 {
  columns: 1;
}

._columns-2 p {
  max-width: 100% !important;
}

._mgb30 {
  margin-bottom: 2.5rem;
}

._w100 {
  width: 100%;
}

._w66 {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
}

@media only screen and (min-width: 1224px) {
  ._w66 {
    width: calc(66.666% - 10px);
  }
}

._w33 {
  width: 100%;
}

@media only screen and (min-width: 1224px) {
  ._w33 {
    width: calc(33.333% - 10px);
  }
}

._w33--2 {
  width: 100%;
}

@media only screen and (min-width: 1224px) {
  ._w33--2 {
    width: calc(33.333% - 13.33px);
  }
}

._w50 {
  width: 100%;
}

@media only screen and (min-width: 1224px) {
  ._w50 {
    width: calc(50% - 10px);
  }
}

._w-mgr {
  margin-right: 0;
}

@media only screen and (min-width: 1224px) {
  ._w-mgr {
    margin-right: 20px;
  }
}

._mga {
  margin: 0;
}

@media only screen and (min-width: 769px) {
  ._mga {
    margin: 0 auto;
  }
}

._pr160 {
  padding-right: 0;
}

@media only screen and (min-width: 769px) {
  ._pr160 {
    padding-right: 160px;
  }
}

.custom-cross {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #fecc00;
  cursor: pointer;
  z-index: 26;
}

.custom-cross:after, .custom-cross:before {
  position: absolute;
  width: 1px;
  height: 36px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #0b3b54;
  content: "";
  transition: transform 0.5s ease, background-color 0.5s ease;
  z-index: 3;
}

.custom-cross:after {
  transform: rotate(45deg);
}

.custom-cross:before {
  transform: rotate(-45deg);
}

.custom-cross span {
  background-color: #d4af1d;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.custom-cross span:after, .custom-cross span:before {
  position: absolute;
  width: 1px;
  height: 66px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #dab418;
  content: "";
  z-index: 2;
  transition: transform 0.5s ease, background-color 0.5s ease;
}

.custom-cross span:after {
  transform: rotate(45deg);
}

.custom-cross span:before {
  transform: rotate(-45deg);
}

.custom-cross:hover:after, .custom-cross:hover:before,
.custom-cross:hover span:before,
.custom-cross:hover span:after {
  background-color: #0b3b54;
}

.custom-cross:hover:after,
.custom-cross:hover span:after {
  transform: scaleX(1.1) rotate(45deg);
}

.custom-cross:hover:before,
.custom-cross:hover span:before {
  transform: scaleX(1.1) rotate(-45deg);
}

.soc-block__link--yt:hover:after {
  background-color: #e40606;
}

.mnew-group {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.idgreed {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-bottom: 20px;
}

@media only screen and (min-width: 1620px) {
  .idgreed {
    padding-left: 50px;
    padding-right: 120px;
  }
}

.right-side {
  width: 100%;
  margin-left: 0;
}

@media only screen and (min-width: 1023px) {
  .right-side {
    width: calc(100% - 360px);
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1366px) {
  .right-side {
    width: calc(100% - 400px);
    margin-left: 40px;
  }
}

@media only screen and (min-width: 1620px) {
  .right-side {
    margin-left: 56px;
    width: calc(100% - 520px);
  }
}

.js-form-file__input {
  display: none;
}

.left-panel {
  width: 360px;
  display: none;
  flex-direction: column;
  background-image: url("../images/dot.png");
}

@media only screen and (min-width: 1023px) {
  .left-panel {
    display: flex;
  }
}

@media only screen and (min-width: 1366px) {
  .left-panel {
    width: 400px;
  }
}

@media only screen and (min-width: 1620px) {
  .left-panel {
    width: 414px;
  }
}

.addthis-widget {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 5;
}

.more-news-block {
  background-color: #fff;
}

.left-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 3.3333333rem;
  background-color: #fff;
}

.left-nav__button-wrapper {
  padding-bottom: 30px;
  background-color: #fff;
}

.left-nav__item {
  padding: 0;
  margin: 0;
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.left-nav__link {
  display: block;
  width: 100%;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  font-size: 24px;
  letter-spacing: 0.1em;
  color: #899297;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
  font-weight: bold;
  padding: 0 90px 0 30px;
  min-height: 92px;
  height: initial;
  display: flex;
  align-items: center;
  align-content: center;
  transition: color 0.5s ease, background-color 0.5s ease, border-color 0.5s ease;
}

.left-nav__link .slider-arrow {
  background-color: #f8f8f8;
  top: 1px;
  right: 1px;
  transition: background-color 0.5s ease;
}

.left-nav__link .slider-arrow:hover:before {
  transform: scaleY(0);
}

.left-nav__link .slider-arrow:hover .arrow-image-wrapper__image-part-2 {
  background-color: #cacede;
}

.left-nav__link .slider-arrow .arrow-image-wrapper__image-part-1 {
  border-color: #0b3b54;
}

.left-nav__link .slider-arrow .arrow-image-wrapper__image-part-1:after {
  background-color: #0b3b54;
}

.left-nav__link .slider-arrow:before {
  transform: scaleX(0);
  transform-origin: right;
}

.left-nav__link.is-opened .slider-arrow {
  transform: rotate(90deg);
}

.left-nav__link:hover, .left-nav__link.is-active {
  color: #0b3b54;
  background-color: #fff;
  border-color: #fecc00;
}

.left-nav__link:hover .slider-arrow:before, .left-nav__link.is-active .slider-arrow:before {
  transform: scaleX(1);
}

.left-nav__link:hover .slider-arrow, .left-nav__link.is-active .slider-arrow {
  background-color: #fff;
}

.left-nav__link:hover .slider-arrow .arrow-image-wrapper__image-part-2, .left-nav__link.is-active .slider-arrow .arrow-image-wrapper__image-part-2 {
  background-color: #d4af1d;
}

.left-nav__link.is-active {
  pointer-events: none;
}

.left-sub-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.left-sub-nav__item {
  display: block;
}

.left-sub-nav__link {
  height: 72px;
  position: relative;
  border-bottom: 1px solid #fecc00;
  border-right: 1px solid #fecc00;
  border-left: 1px solid #fecc00;
  display: flex;
  align-items: center;
  align-content: center;
  padding-left: 48px;
  text-decoration: none;
  background-color: #f8f8f8;
  color: #899297;
  padding-right: 60px;
  font-family: 'Bebas Neue';
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
}

.left-sub-nav__link.is-active {
  background-color: #fecc00;
  color: #0b3b54;
  pointer-events: none;
}

.left-sub-nav__link.is-active:after {
  background-color: #d4af1d;
}

.left-sub-nav__link:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 39px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-color: #cfd2e3;
}

.left-sub-nav__link:hover {
  color: #0b3b54;
  background-color: #fff;
}

.items-grid,
.videos-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.item {
  margin-right: 0;
  margin-bottom: 18px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  max-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 455px;
  align-content: center;
}

@media only screen and (min-width: 480px) {
  .item {
    width: calc(50% - 18px);
    margin-right: 18px;
  }
}

.item:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #14384b;
  z-index: 2;
}

.item__image {
  position: relative;
  z-index: 1;
  transition: transform 1s ease;
}

.item__title {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 22px;
  line-height: 100%;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
  transition: color 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .item__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1366px) {
  .item__title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1620px) {
  .item__title {
    font-size: 30px;
  }
}

.item__title b {
  display: block;
}

.item:hover .item__title {
  color: #fecc00;
}

.item:hover .item__image {
  transform: scale(1.1);
}

.videos-grid {
  justify-content: center;
  margin-bottom: 4.1666667rem;
}

@media only screen and (min-width: 1366px) {
  .videos-grid {
    justify-content: flex-start;
  }
}

.video-item {
  margin-right: 0;
  margin-bottom: 18px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  max-width: 460px;
}

@media only screen and (min-width: 732px) {
  .video-item {
    width: calc(50% - 18px);
    margin-right: 18px;
  }
}

@media only screen and (min-width: 1366px) {
  .video-item {
    width: calc(33.33% - 18px);
    margin-right: 18px;
  }
}

.video-item--f {
  cursor: default;
  display: none;
}

.video-item--f:after {
  display: none;
}

@media only screen and (min-width: 480px) {
  .video-item--f {
    display: block;
  }
}

.video-item__image {
  position: relative;
  z-index: 1;
  transition: transform 1s ease;
}

.video-item__title-image {
  display: flex;
  margin-bottom: 28px;
  padding-top: 60px;
}

.video-item__title-image svg {
  width: 52px;
  height: 40px;
  fill: #fff;
  transition: fill 0.5s ease;
}

.video-item__title {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 22px;
  line-height: 100%;
  color: #fff;
  flex-direction: column;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
  transition: color 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .video-item__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1366px) {
  .video-item__title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1620px) {
  .video-item__title {
    font-size: 30px;
  }
}

.video-item__title b {
  display: block;
}

.video-item:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #14384b;
  z-index: 2;
}

.video-item:hover .video__title {
  color: #fecc00;
}

.video-item:hover .video__title-image svg {
  fill: #fecc00;
}

.video-item:hover .video__image {
  transform: scale(1.1);
}

.items-slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 46px;
}

.items-slider__main-wrapper {
  position: relative;
  margin-bottom: 20px;
  max-width: 940px;
  width: 100%;
}

.items-slider__main {
  width: 100%;
}

.items-slider__main-item {
  height: 370px;
  border-right: none;
  background-position: 50%;
  background-size: cover;
}

.items-slider__thumbs-item {
  cursor: pointer;
}

.items-slider .slider-arrow-group {
  bottom: 1px;
  right: 1px;
}

.item-descr__table {
  width: 100%;
  display: flex;
  max-width: 936px;
  flex-direction: column;
}

.item-descr__table-row {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1023px) {
  .item-descr__table-row {
    flex-direction: row;
  }
}

.item-descr__table-row:nth-child(1) .item-descr__table-D {
  border-top: 1px solid #fecc00;
}

.item-descr__table-row .item-descr__table-D:nth-child(1) {
  font-weight: bold;
  color: #0b3b54;
  width: 100%;
}

@media only screen and (min-width: 1023px) {
  .item-descr__table-row .item-descr__table-D:nth-child(1) {
    width: 25%;
  }
}

.item-descr__table-row .item-descr__table-D:nth-child(2) {
  color: #899297;
  width: 100%;
  margin-left: 0;
  font-weight: normal;
  padding-right: 10%;
}

@media only screen and (min-width: 1023px) {
  .item-descr__table-row .item-descr__table-D:nth-child(2) {
    width: 75%;
    margin-left: 34px;
  }
}

@media only screen and (min-width: 1620px) {
  .item-descr__table-row .item-descr__table-D:nth-child(2) {
    padding-right: 0;
  }
}

.item-descr__table-D {
  font-size: 16px;
  line-height: 50px;
  font-family: 'MagistralC';
  padding: 0;
  border-bottom: 1px solid #fecc00;
}

.news-page-image {
  position: relative;
  width: 100%;
  max-height: 418px;
  margin-bottom: 3.1666667rem;
}

.news-page-image img {
  max-width: 710px;
  max-height: 418px;
  position: relative;
  z-index: 2;
  width: 100%;
}

.news-page-image:after {
  position: absolute;
  left: -104px;
  top: 0;
  width: calc(100% + 104px);
  content: "";
  height: 100%;
  z-index: 1;
  background-image: url(../images/dot.png);
}

.inner-news__bottom-row {
  display: flex;
  justify-content: space-between;
  height: 90px;
  align-content: center;
  margin-bottom: 2.5rem;
  align-items: center;
}

.inner-news__to-left, .inner-news__to-right {
  color: #6a73aa;
  text-decoration: none;
  display: block;
  outline: none;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
  font-size: 24px;
  height: 90px;
  display: flex;
  align-items: center;
  align-content: center;
}

.inner-news__to-left .slider-arrow, .inner-news__to-right .slider-arrow {
  position: relative;
}

.inner-news__to-left:hover .slider-arrow:before, .inner-news__to-right:hover .slider-arrow:before {
  transform: scaleY(1);
}

.inner-news__to-left:hover .slider-arrow .arrow-image-wrapper__image-part-1, .inner-news__to-right:hover .slider-arrow .arrow-image-wrapper__image-part-1 {
  border-color: #0b3b54;
}

.inner-news__to-left:hover .slider-arrow .arrow-image-wrapper__image-part-1:after, .inner-news__to-right:hover .slider-arrow .arrow-image-wrapper__image-part-1:after {
  background-color: #0b3b54;
}

.inner-news__to-left:hover .slider-arrow .arrow-image-wrapper__image-part-2, .inner-news__to-right:hover .slider-arrow .arrow-image-wrapper__image-part-2 {
  background-color: #d4af1d;
}

.inner-news__to-left:hover .slider-arrow .arrow-image-wrapper__image-part-2:after, .inner-news__to-right:hover .slider-arrow .arrow-image-wrapper__image-part-2:after {
  border-color: #d4af1d;
}

.inner-news__text {
  display: none;
}

@media only screen and (min-width: 1023px) {
  .inner-news__text {
    display: block;
  }
}

.inner-news__to-left .inner-news__text {
  padding-left: 20px;
}

@media only screen and (min-width: 1366px) {
  .inner-news__to-left .inner-news__text {
    padding-left: 3.3333333rem;
  }
}

.inner-news__to-right .slider-arrow {
  order: 2;
}

.inner-news__to-right .inner-news__text {
  order: 1;
  padding-right: 20px;
}

@media only screen and (min-width: 1366px) {
  .inner-news__to-right .inner-news__text {
    padding-right: 3.3333333rem;
  }
}

.inner-news__back-button {
  height: 40px;
  width: 300px;
  background-color: #fecc00;
  text-decoration: none;
  display: block;
  outline: none;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
  font-size: 18px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

@media only screen and (min-width: 1023px) {
  .inner-news__back-button {
    height: 90px;
    font-size: 24px;
    width: 320px;
  }
}

@media only screen and (min-width: 1366px) {
  .inner-news__back-button {
    width: 390px;
  }
}

.inner-news__back-button:hover {
  color: #0b3b54;
}

.presentations-grid {
  display: flex;
  flex-wrap: wrap;
}

.presentation-item {
  position: relative;
  height: auto;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  background-color: #f8f8f8;
  transition: background-color 0.5s ease;
  flex-direction: column;
}

@media only screen and (min-width: 500px) {
  .presentation-item {
    flex-direction: row;
    height: 248px;
  }
}

@media only screen and (min-width: 1023px) {
  .presentation-item {
    width: calc(50% - 10px);
  }
}

.presentation-item--null {
  background-image: url(../images/dot.png);
  pointer-events: none;
  display: none;
}

@media only screen and (min-width: 1023px) {
  .presentation-item--null {
    display: block;
  }
}

.presentation-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: opacity 0.5s ease;
  content: "";
}

.presentation-item:nth-child(odd) {
  margin-right: 0;
}

@media only screen and (min-width: 1023px) {
  .presentation-item:nth-child(odd) {
    margin-right: 20px;
  }
}

.presentation-item__image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: transparent;
}

@media only screen and (min-width: 500px) {
  .presentation-item__image {
    width: 120px;
    height: 100%;
  }
}

.presentation-item__image svg {
  fill: #fff;
  width: 54px;
  height: 54px;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
}

@media only screen and (min-width: 500px) {
  .presentation-item__image svg {
    margin-bottom: 54px;
  }
}

.presentation-item__image:after {
  width: 120%;
  height: 120%;
  background-color: #899297;
  position: absolute;
  left: -33%;
  top: 0;
  content: "";
  z-index: 1;
  transform: skew(-6deg);
  transition: background-color 0.5s ease;
}

.presentation-item__title {
  color: #899297;
  font-size: 20px;
  line-height: 110%;
  font-weight: 300;
  letter-spacing: 0.1em;
  font-family: 'Bebas Neue';
  transition: color 0.5s ease;
  margin-bottom: 20px;
}

@media only screen and (min-width: 732px) {
  .presentation-item__title {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 1160px) {
  .presentation-item__title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1366px) {
  .presentation-item__title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1620px) {
  .presentation-item__title {
    font-size: 30px;
  }
}

.presentation-item__title b {
  display: block;
}

.presentation-item__format {
  color: #fecc00;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'MagistralC';
}

.presentation-item__descr {
  position: relative;
  height: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 40px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (min-width: 375px) {
  .presentation-item__descr {
    padding-top: 60px;
    padding-left: 60px;
  }
}

@media only screen and (min-width: 500px) {
  .presentation-item__descr {
    width: calc(100% - 120px);
  }
}

@media only screen and (min-width: 1366px) {
  .presentation-item__descr {
    padding-top: 70px;
    padding-left: 70px;
    padding-right: 20px;
  }
}

.presentation-item__descr .bg-holder__left {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

@media only screen and (min-width: 732px) {
  .presentation-item__descr .bg-holder__left {
    display: block;
  }
}

.presentation-item__descr .bg-holder__left:after {
  display: none;
}

.presentation-item__descr .bg-holder__right span, .presentation-item__descr .bg-holder__left span, .presentation-item__descr .bg-holder__center span {
  margin: 0 0 0 auto;
}

.presentation-item .news-item__button {
  z-index: 15;
}

.presentation-item .bg-holder__left {
  display: none;
}

@media only screen and (min-width: 375px) {
  .presentation-item .bg-holder__left {
    display: block;
  }
}

.presentation-item:hover {
  z-index: 2;
  background-color: #fff;
}

.presentation-item:hover .presentation-item__image:after {
  background-color: #fecc00;
}

.presentation-item:hover:after {
  opacity: 0;
}

@media only screen and (min-width: 732px) {
  .presentation-item:hover:after {
    opacity: 1;
  }
}

.presentation-item:hover .news-item__button {
  color: #fff;
  background-color: #0b3b54;
}

.presentation-item:hover .presentation-item__title {
  color: #0b3b54;
}

.m-menu__link {
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: #0b3b54;
  font-family: 'Bebas Neue';
  letter-spacing: 0.1em;
}

.finding-items {
  color: #fecc00;
  margin-bottom: 2rem;
  font-size: 24px;
  font-family: 'MagistralC';
}

#m_menu {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-decoration: none;
  color: #0b3b54;
  font-family: 'Bebas Neue';
}

#m_menu button {
  height: 100%;
}

#m_menu button .button__content {
  background-color: #0b3b54;
  height: 100%;
  color: #fff;
  padding: 0;
}

#m_menu button:after {
  display: none;
}

.mm-navbar-bottom {
  display: flex;
  justify-content: space-around;
}

.search-item {
  position: relative;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  z-index: 1;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 30px;
  padding-right: 10px;
  background-color: #f8f8f8;
  transition: background-color 0.5s ease;
  justify-content: space-between;
  height: 246px;
}

@media only screen and (min-width: 732px) {
  .search-item {
    padding-top: 70px;
    padding-left: 70px;
    padding-bottom: 34px;
    padding-right: 20px;
    height: 300px;
  }
}

@media only screen and (min-width: 1023px) {
  .search-item {
    padding-top: 70px;
    padding-left: 70px;
    padding-bottom: 34px;
    padding-right: 170px;
  }
}

.search-item:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px 8px 52px -3px rgba(0, 0, 0, 0.75);
  opacity: 0;
  transition: opacity 0.5s ease;
  content: "";
}

.search-item .bg-holder__left {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
}

@media only screen and (min-width: 732px) {
  .search-item .bg-holder__left {
    display: block;
  }
}

.search-item .bg-holder__left:after {
  display: none;
}

.search-item .bg-holder__right span, .search-item .bg-holder__left span, .search-item .bg-holder__center span {
  margin: 0 0 0 auto;
}

.search-item__text {
  font-size: 16px;
  color: #7d7d7d;
  line-height: 24px;
  font-family: 'Proxima Nova Rg';
  font-weight: 300;
  margin-bottom: auto;
  height: 72px;
  overflow: hidden;
}

.search-item__type {
  text-transform: uppercase;
  font-size: 14px;
  color: #fecc00;
  font-family: 'MagistralC';
  font-weight: bold;
}

.search-item__title {
  color: #899297;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-family: 'Bebas Neue';
  margin-bottom: 20px;
  max-height: 60px;
  overflow: hidden;
  transition: color 0.5s ease;
}

@media only screen and (min-width: 1160px) {
  .search-item__title {
    font-size: 30px;
    line-height: 36px;
  }
}

.search-item:hover {
  z-index: 2;
  background-color: #fff;
}

.search-item:hover:after {
  opacity: 0;
}

@media only screen and (min-width: 732px) {
  .search-item:hover:after {
    opacity: 1;
  }
}

.search-item:hover .news-item__button {
  color: #fff;
  background-color: #0b3b54;
}

.search-item:hover .search-item__title {
  color: #0b3b54;
}

.search-grid {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.page404-content {
  min-height: auto;
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 25;
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding-left: 10px;
  padding-top: 60px;
  padding-right: 10px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 1023px) {
  .page404-content {
    min-height: calc(100vh - 260px);
    padding-left: 288px;
    padding-top: 0;
    top: 110px;
    position: fixed;
    padding-bottom: 0;
  }
}

.page404-content__title {
  color: #fecc00;
  font-size: 38px;
  line-height: 100%;
  letter-spacing: 0.1em;
  font-weight: bold;
  font-family: 'Bebas Neue';
  margin-bottom: 40px;
}

@media only screen and (min-width: 1160px) {
  .page404-content__title {
    font-size: 48px;
  }
}

.page404-content__text {
  font-size: 20px;
  color: #fff;
  line-height: 30px;
  font-family: 'MagistralC';
  margin-bottom: 50px;
}

@media only screen and (min-width: 1160px) {
  .page404-content__text {
    font-size: 24px;
  }
}

.page404-content__link {
  letter-spacing: 0.05em;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Bebas Neue';
}

@media only screen and (min-width: 1160px) {
  .page404-content__link {
    font-size: 24px;
  }
}

.page404-content__link-wrapper {
  margin-bottom: 10px;
}

.popup-form {
  background-color: #fecc00;
  padding: 38px 10px 50px;
  margin-top: 90px;
}

@media only screen and (min-width: 1224px) {
  .popup-form {
    padding: 38px 38px 50px;
  }
}

.popup-form__title {
  font-size: 40px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
  font-family: 'Bebas Neue';
  color: #0b3b54;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1366px) {
  .popup-form__title {
    font-size: 48px;
    letter-spacing: 0.1em;
  }
}

.popup-form__subtitle {
  font-size: 24px;
  font-weight: bold;
  color: #7f6600;
  font-family: 'MagistralC';
  margin-bottom: 10px;
}

.popup-form__group {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1366px) {
  .popup-form__group {
    margin-bottom: 50px;
  }
}

.popup-form .control-holder {
  margin-bottom: 12px;
}

.popup-form__button-wrapper {
  display: flex;
  justify-content: center;
}

.form-group__row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

@media only screen and (min-width: 1224px) {
  .form-group__row {
    flex-wrap: nowrap;
  }
}

.form-group__end-value-block {
  background-color: #7f6600;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 5px 9px;
}

@media only screen and (min-width: 1224px) {
  .form-group__end-value-block {
    padding: 16px 10px;
  }
}

.form-group__end-value {
  font-size: 18px;
  font-family: 'MagistralC';
  color: #fecc00;
}

@media only screen and (min-width: 1224px) {
  .form-group__end-value {
    font-size: 24px;
  }
}

.form-group__end-value span {
  font-weight: bold;
  font-size: 24px;
}

@media only screen and (min-width: 1224px) {
  .form-group__end-value span {
    font-size: 30px;
  }
}

.form-toggler__name {
  font-weight: bold;
  color: #fecc00;
  font-size: 14px;
  line-height: 26px;
  height: 100%;
  font-family: 'MagistralC';
  padding-right: 10px;
}

@media only screen and (min-width: 480px) {
  .form-toggler__name {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1224px) {
  .form-toggler__name {
    font-size: 21px;
    line-height: 36px;
  }
}

.hidden-checkbox {
  display: none;
}

.mfp-close {
  display: none !important;
}

.custom-mfp-close {
  position: absolute;
  width: 90px;
  height: 90px;
  top: -92px;
  right: 0;
  background-color: #fecc00;
  cursor: pointer;
  z-index: 26;
}

.custom-mfp-close:after, .custom-mfp-close:before {
  position: absolute;
  width: 1px;
  height: 36px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #0b3b54;
  content: "";
  transition: transform 0.5s ease, background-color 0.5s ease;
  z-index: 3;
}

.custom-mfp-close:after {
  transform: rotate(45deg);
}

.custom-mfp-close:before {
  transform: rotate(-45deg);
}

.custom-mfp-close span {
  background-color: #d4af1d;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.custom-mfp-close span:after, .custom-mfp-close span:before {
  position: absolute;
  width: 1px;
  height: 66px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-color: #dab418;
  content: "";
  z-index: 2;
  transition: transform 0.5s ease, background-color 0.5s ease;
}

.custom-mfp-close span:after {
  transform: rotate(45deg);
}

.custom-mfp-close span:before {
  transform: rotate(-45deg);
}

.custom-mfp-close:hover:after, .custom-mfp-close:hover:before,
.custom-mfp-close:hover span:before,
.custom-mfp-close:hover span:after {
  background-color: #0b3b54;
}

.custom-mfp-close:hover:after,
.custom-mfp-close:hover span:after {
  transform: scaleX(1.1) rotate(45deg);
}

.custom-mfp-close:hover:before,
.custom-mfp-close:hover span:before {
  transform: scaleX(1.1) rotate(-45deg);
}

.idk-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.idk-item {
  width: calc(33.33% - 20px);
  margin: 10px;
  height: 400px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  min-width: 240px;
}

.idk-item img {
  max-width: 100%;
  max-height: 100%;
  transition: transform 1.5s ease;
}

.idk-item:hover img {
  transform: scale(1.1);
}

.tnx {
  padding-top: 22vh;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

@media only screen and (min-width: 1160px) {
  .tnx {
    padding-top: 12vh;
  }
}

.tnx__title {
  font-size: 4rem;
  color: #fecc00;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-align: center;
  margin-bottom: 3rem;
}

.tnx__text {
  font-size: 2.5rem;
  line-height: 3rem;
  color: #fff;
  text-align: center;
  margin-bottom: 5rem;
}

.tnx__text b {
  display: block;
}

.tnx .custom-mfp-close {
  position: relative;
  top: 0;
  bottom: auto;
  right: auto;
  left: 0;
  margin: 0 auto;
}

.mfp-bg {
  height: 200% !important;
  bottom: 0;
  margin: auto;
}

.slick-track {
  margin: 0 auto;
}

.mfp-content {
  margin-top: 40px;
}

.zoom-in.mfp-ready.mfp-bg {
  opacity: 0 !important;
}

.mfp-container {
  height: auto !important;
}

.mfp-container:after {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  opacity: 0.8;
  content: "";
}
