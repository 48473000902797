@font-face {
	font-family: 'Bebas Neue';
	src: url('fonts/subset-BebasNeueBold.woff2') format('woff2'),
		url('fonts/subset-BebasNeueBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('fonts/subset-BebasNeueLight.woff2') format('woff2'),
		url('fonts/subset-BebasNeueLight.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('fonts/subset-BebasNeueRegular.woff2') format('woff2'),
		url('fonts/subset-BebasNeueRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'MagistralC';
	src: url('fonts/subset-MagistralC-Bold.woff2') format('woff2'),
		url('fonts/subset-MagistralC-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('fonts/subset-ProximaNova-Regular.woff2') format('woff2'),
		url('fonts/subset-ProximaNova-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MagistralC';
	src: url('fonts/subset-MagistralC.woff2') format('woff2'),
		url('fonts/subset-MagistralC.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova Rg';
	src: url('fonts/subset-ProximaNova-Light.woff2') format('woff2'),
		url('fonts/subset-ProximaNova-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}
